import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import '../../css/index.css';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
      fontSize:"20px",
   fontWeight:"bold",marginTop:"10px"
  },
}));

export default function SimpleExpansionPanel(props) {
  const classes = useStyles();
  const {HeaderText,image,content}=props;

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary  style={{border:"1px solid gray",padding:"4px", minHeight:"20px",margin:"0"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <img src={image} style={{width:'50px',marginRight:"50px"}} />
                <Typography className={classes.heading}>{HeaderText}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{width:"100%"}}>{content}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
       
    </div>
  );
}