import React from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import {Button} from 'primereact/button';



const DataTableDemo=(props)=>{
  const {data,pumpMechanicalCols}=props;

  

      const pumpMechCol=pumpMechanicalCols.map((v,index)=>(
        <Column style={{width:"120px",textOverflow: "ellipsis"}} key={index} field={v.field} header={v.header} />
      ))


  return(<div style={{width:"100%",margin:"0 auto",marginTop:"20px"}}>

   
  {data.length!=0?<DataTable value={data} paginator={true} rows={10}  
   resizableColumns={true}
   reorderableColumns={true}
   onRowReorder={(e) => this.setState({products: e.value})}
   paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" >
     {pumpMechCol}
  </DataTable>:<div>No Records Found</div>}

  </div>)
}


export default DataTableDemo;

// var header = <div style={{textAlign:'left'}}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
// Id							
// DeviceId					
// DateTime					
// DoneBy						
// PECINCharge					
// ConditionOfEquipement		
// MechanicalSeal				
// Bearings					
// GlandSeals					
// Lubrication					
// Spider						
// ImpellerComb				
// EquipmentCleaning			
// ValvesInspection			
// SafetyValvesPressureGaugeNRV
// AllfoundationBolts			
// ChainPulleyInspection		
// CouplingGearBox				
// OilLevelAndTopup			
// Greasing			


// Mechanical seal
//   Bearings
//   Gland/seals
//   Lubrication
//   Spider
//   Impeller / Comb
//   Equipment cleaning
//   Valves inspection
//   "Safety valves/
//   Pressure gauge/NRV"
//   All foundation Bolts
//   Chain pulley inspection
//   Coupling/Gear box
//   Oil level & Top up
//   Greasing



// var products = [
//   {id: 1,name: "Product1",price: 120},
//   {id: 2,name: "Product1",price: 120},
//   {id: 3,name: "Product1",price: 120},
//   {id: 4,name: "Product12",price: 120},
//   {id: 5,name: "Product22",price: 120},
//   {id: 6,name: "Product1",price: 120},
//   {id: 7,name: "Product1",price: 120}, ]
