import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";

//Application components
import ToolComponent from "./ToolMaintain";
import { EquipmentList, DeviceType } from "../constants/appConstants";
import SelectCompoent from "../Helpers/Dropdown";
import PMCheckList from "../PMCheckList";
import RepairComponent from "../Repair";
import ReplacementComponent from '../Replacement';
import Spinner from '../Helpers/Spinner';
// import Report from '../../containers/Report';
import SelectReportComponent from './DashboardHelpers/selectReport';
import LogbookComponent from '../Logbook';

import ReportComponent from '../Report';



const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: "0px",
    // border: "1px solid gray",
    padding: "0px",
  },
  paperCheckList: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: "0px",
    padding: "10px",
    border: "1px solid gray",
    padding: "0px"
  },
  paperContent: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: "0px",
    border: "1px solid gray",
    padding: "10px",
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      margin: "5px"
    }
  },

  root: {
    width: "97%",
    margin: "0 auto",
    marginTop: "80px",
    // marginBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto",
      marginTop: "80px",
      marginBottom: "50px"
    }
  },
  tabContainer: {
    backgroundColor: "#0000b3",
   
    border: "1px solid #6666ff",
    textAlign: "center",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    padding: "15px 0px",
    [theme.breakpoints.down("xs")]: {
      width:"31.40%",float:"left",padding: "5px 0 7px 0 ",margin:"3px",borderRadius:"3px",
      fontSize: "12px", fontWeight: "normal",
      border: "1px solid #fff",
    },
    "&:hover": {
      backgroundColor: "#1a66ff",
      cursor: "pointer"
    }
  },
  selectedTab: {
    backgroundColor: "#0000ff",
    border: "1px solid #6666ff",
    textAlign: "center",
    color: "#fff",
    fontSize: "14px",
    padding: "15px 0",
    [theme.breakpoints.down("xs")]: {
      width:"31.40%",float:"left",padding: "5px 0 7px 0 ",margin:"3px",borderRadius:"3px",
      fontSize: "12px",border: "1px solid #fff",
    },
    "&:hover": {
      backgroundColor: "#1a66ff",
      cursor: "pointer"
    }
  },
  toolContent: {
    // border:"1px solid gray",
    // padding:"5px",
    margin: "20px 5px 5px 5px"
  },
  header: {
    textAlign: "left",
    backgroundColor: "#e6e6e6",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "10px"
  },

  paper_Check_List: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: "0px",
    padding: "10px",
    marginTop:"10px",
    border: "1px solid gray",
    padding: "0px",
    marginLeft:"10px",
    [theme.breakpoints.down("xs")]: {
     marginLeft:"5px", marginRight:"5px"
    }
  },
  menuitems:{
    padding:"100px 0 100px 0",
    backgroundColor:"#0000ff",
    [theme.breakpoints.down("xs")]: {
      padding:"0px",
     }
  },
  compSection:{
     padding: "0px 10px 10px 10px",
     [theme.breakpoints.down("xs")]: {
      padding: "5px"
     }
    }
});

const DashboardCompoment = (props) => {
  const {
    classes,history,
    locations,
    deviceType,
    locationName,
    deviceNames,
    getDeviceInfo,
    deviceNameType,
    deviceData,
    typeID,
    loc_Id,
    device_Name,
    getPumpCheckList,
    getBlowerCheckList,
    pbCheckListData,
    blowerCheckList,
    getRepairStatus,
    isLoading,
    

    //Save functions
    savePumpCheckList,
    saveBlowerCheckList,
    saveRepairStatusPB,
    getRepairStatusInfo,

    saveRepairStatus,
    getReplacementInfo,
    getReplacementData,
    saveReplacementDate,

    //Report 
    getPumpReport,
    getBlowerReport,
    GetPumpRepairReport,
    GetBlowerRepairReport,
    GetPumpReplacementReport,
    PupmReportLsit,
    blowerReport,
    pumpRepairReport,
    pumpReplacementReport,
    isLoad_Report

    
    

    
  } = props;
 
 

  const [tabSelected, setIsSelected] = useState("");
  const selectedTab = value => {
    ;
    setIsSelected(value);
    if(value==="Maintanance" && deviceNameType==="pump"){
      getPumpCheckList(typeID)
    }
    if(value==="Maintanance" && deviceNameType==="blower"){
      ;
      getBlowerCheckList(typeID)
    }
    if(value==="Repair"){
      // getLocations();
      getRepairStatus(typeID)
    }
    if(value==="Replacement"){
      getReplacementData(typeID)
    }
  };

 

  const getReports=(value)=>{
    setIsSelected(value);
  }

  const [devType,setDevType]=useState();
  const clearData=(value)=>{
    setDevType(value)
  }

  const [actionItems,setActionItems]=useState();
  const getActionItems=(value)=>{
    setActionItems(value)
  }


  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item lg={2} xs={12} sm={3} style={{backgroundColor:"#0080ff",padding:"10px 0 5px 6px"}}>
          <div className={classes.menuitems} >
          <Paper className={classes.paper} >
            <div onClick={typeID?() => selectedTab("Maintanance"):""} 
            className={tabSelected === "Maintanance"? classes.tabContainer: classes.selectedTab}>PM Check List</div>
            <div onClick={typeID?() => selectedTab("Repair"):""}
              className={
                tabSelected === "Repair"
                  ? classes.tabContainer
                  : classes.selectedTab
              }
            >
              Repair
            </div>
            <div
              onClick={typeID?() => selectedTab("Replacement"):""}
              className={
                tabSelected === "Replacement"
                  ? classes.tabContainer
                  : classes.selectedTab
              }
            >
              Replacement
            </div>
            <div
              onClick={() => getReports("Report")}
              className={
                tabSelected === "Report"
                  ? classes.tabContainer
                  : classes.selectedTab
              }
            >
              Report
            </div>
            <div
              onClick={() => getReports("Logbook")}
              className={
                tabSelected === "Logbook"
                  ? classes.tabContainer
                  : classes.selectedTab
              }
            >
              Logbook
            </div>
          </Paper>
          </div>
        </Grid>
        <Grid item lg={10} xs={12} sm={9}>
          <Paper className={classes.paperContent}>
            <div className={classes.header}>DEVICE INFORMATION </div>
            <div>
              <Grid container spacing={3}>
                <Grid item lg={3} sm={4} xs={4}>
                  <SelectCompoent
                    text="Device Type"
                    func={deviceType}
                    displayData={DeviceType}
                  />
                </Grid>
                <Grid item lg={3} sm={4} xs={4}>
                  <SelectCompoent
                    text="Location"
                    func={locationName}
                    displayData={locations}
                  />
                </Grid>
                <Grid item lg={3} sm={4} xs={4}>
                  <SelectCompoent
                    text="Device Name"
                    func={getDeviceInfo}
                    displayData={deviceNames}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              {deviceData.length != 0 ? (
                <ToolComponent type={deviceNameType} data={deviceData} />
              ) : (
                ""
              )}
            </div>
          </Paper>
          {tabSelected?<Paper className={classes.paper_Check_List}>
            <div className={classes.compSection}  >
            {tabSelected === "Maintanance" ? 
                (isLoading?<div style={{width:"100%",textAlign:"center",marginTop:"10px"}}><Spinner /></div>:<PMCheckList deviceNameType={deviceNameType}
                  getBlowerCheckList={getBlowerCheckList}
                  typeID={typeID}
                   savePumpCheckList={savePumpCheckList} saveBlowerCheckList={saveBlowerCheckList}
                data={deviceNameType==="pump"?pbCheckListData:blowerCheckList} isLoading={isLoading} />)
             :""}
              </div>
              <div  className={classes.compSection}  >
            {tabSelected === "Repair" ?(isLoading?<div style={{width:"100%",textAlign:"center",marginTop:"10px"}}><Spinner /></div>: 
                <RepairComponent saveRepairStatusPB={saveRepairStatusPB} getRepairStatus={getRepairStatus}  data={getRepairStatusInfo} typeID={typeID}/>)
             :""}
              </div>

              <div className={classes.compSection} >
            {tabSelected === "Replacement" ?(isLoading?<div style={{width:"100%",textAlign:"center",marginTop:"10px"}}><Spinner /></div>: 
                <ReplacementComponent typeID={typeID}  getReplacementData={getReplacementData} saveReplacementDate={saveReplacementDate} getReplacementInfo={getReplacementInfo} />)
             :""}
              </div>

              <div className={classes.compSection} >
            {tabSelected === "Report" ?<>
            <SelectReportComponent func={clearData} funcActionItems={getActionItems}
            getPumpReport={getPumpReport} 
            getBlowerReport={getBlowerReport}
            GetPumpRepairReport={GetPumpRepairReport}
            GetBlowerRepairReport={GetBlowerRepairReport}
            GetPumpReplacementReport={GetPumpReplacementReport}
            />
            {isLoad_Report?<div style={{width:"100%",textAlign:"center",marginTop:"10px"}}><Spinner /></div>: 
            (PupmReportLsit.length!=0 || blowerReport.length!=0 || pumpRepairReport.length!=0?<ReportComponent 
              actionItems={actionItems} devType={devType}
            PupmReportLsit={devType==="Pump"?PupmReportLsit:""}
            blowerReport={devType==="Blower"?blowerReport:""}
            pumpRepairReport={pumpRepairReport}
            pumpReplacementReport={pumpReplacementReport}
              />:"")}
            </>:""}
              </div>
              <div className={classes.compSection} >
            {tabSelected === "Logbook" ?(isLoading?<div style={{width:"100%",textAlign:"center",marginTop:"10px"}}><Spinner /></div>: 
                <LogbookComponent  />)
             :""}
              </div>
          </Paper>:""}
        </Grid>
      </Grid>
    </div>
  );
};
export default withStyles(styles)(withRouter(DashboardCompoment));

{/* <div style={{ padding: "0px 10px 10px 10px"}}>
{tabSelected === "Report"?  
    <Report  />
 :""}
  </div> */}


{
  /* <Grid container spacing="2"> 
          
<Grid item lg={2} sm={3} xs={12}>
<Grid container spacing={3}>
<Grid item lg={12} sm={12} xs={6} onClick={() => selectedTab("Maintanance")} className={tabSelected === "Maintanance" ? classes.tabContainer : classes.selectedTab}>Maintanance</Grid>
<Grid item lg={12} sm={12} xs={6} onClick={() => selectedTab("Repair")} className={tabSelected === "Repair" ? classes.tabContainer : classes.selectedTab}>Repair</Grid>
<Grid item lg={12} sm={12} xs={6} onClick={() => selectedTab("Eqipment Replacement")} className={tabSelected === "Eqipment Replacement" ? classes.tabContainer : classes.selectedTab}>Eqipment Replacement</Grid>
<Grid item lg={12} sm={12} xs={6} onClick={() => selectedTab("Operation")} className={tabSelected === "Operation" ? classes.tabContainer : classes.selectedTab}>Operation</Grid>
</Grid>
</Grid>
<Grid item lg={10} sm={9} xs={12} >
    <div className={classes.header} >Pump / Blower Details</div>
    <div style={{padding:"10px"}}> {tabSelected === "Maintanance" ?
<ToolComponent
isProb="false"
text="Select Area"
Eql="Equipment list "
Location={Locations}
EquipmentList={EquipmentList}
/> : ""}</div>

</Grid>
</Grid> */
}

{
  /* <div style={{ padding: "10px" }}>
<Grid container spacing="2">
    <Grid item lg={3} sm={3} xs={6} onClick={() => selectedTab("Maintanance")} className={tabSelected === "Maintanance" ? classes.tabContainer : classes.selectedTab}>Maintanance</Grid>
    <Grid item lg={3} sm={3} xs={6} onClick={() => selectedTab("Repair")} className={tabSelected === "Repair" ? classes.tabContainer : classes.selectedTab}>Repair</Grid>
    <Grid item lg={3} sm={3} xs={6} onClick={() => selectedTab("Eqipment Replacement")} className={tabSelected === "Eqipment Replacement" ? classes.tabContainer : classes.selectedTab}>Eqipment Replacement</Grid>
    <Grid item lg={3} sm={3} xs={6} onClick={() => selectedTab("Operation")} className={tabSelected === "Operation" ? classes.tabContainer : classes.selectedTab}>Operation</Grid>
</Grid>
</div>
<div className={classes.toolContent}>
{tabSelected === "Maintanance" ?
    <ToolComponent
        isProb="false"
        text="Select Area"
        Eql="Equipment list "
        Location={Locations}
        EquipmentList={EquipmentList}
    /> : ""}
{tabSelected === "Repair" ?
    <ToolComponent
        isProb="true"
        text="Select Area"
        Eql="Equipment list "
        problem="Problems"
        Location={Locations}
        EquipmentList={EquipmentList}
        ProblemList={ProblemList}
    /> : ""}
{tabSelected === "Eqipment Replacement" ?
    <ToolComponent
        isProb="false"
        text="Select Area"
        Eql="Equipment list "
        Location={Locations}
        EquipmentList={EquipmentList}
    /> : ""}
{tabSelected === "Operation" ? "Comming Soon....." : ""}
</div> */
}
