import Axios from 'axios';

export const get = (url) => {
  return Axios.get(url).then(
    resp => {
      return resp;
    },
    err => {
      console.error("Error from GET call of ", url);
    }
  );
};

export const post = (url, data) => {
  return Axios.post(url, data,
    {headers: {
          'Content-Type': 'application/json'
      }}).then(
    resp => {
      return resp;
    },
    err => {
      console.error("Error from POST call of ", url);
    }
  );
};
