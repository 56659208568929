import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {Link, withRouter } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import NumberFormat from "react-number-format";


const styles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop:"100px",
      marginBottom:"100px",
      [theme.breakpoints.down("xs")]: {
        marginTop:"80px",
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
      border:"1px solid #384ec7",
      padding:"20px",
      borderRadius:"3px"
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    divider:{
      height:"10px",
      marginBottom:"10px"
    },
    registerSec:{
      border:"1px solid #661a00",
      borderRadius:"0px",
     padding:"10px",
     [theme.breakpoints.down("xs")]:{ 
      padding:"10px",
    }
    },
    loginNav:{textAlign:"center",fontSize:"14px"},
    textField: {
      height: "30px",
      width:"100%",
      fontSize:"14px",
      marginTop:"10px"
  },textField1: {
    height: "30px",
    width:"100%",
    fontSize:"14px",
    marginTop:"10px",
    marginBottom:"15px"
},
  });


const RegisterComponent=(props)=>{
    const {classes, handleChange, registerVal, handleSubmit,errorRegister } = props;



    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset",fontSize:"14px",padding:"10px"};
    return(
        <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <PersonOutlineIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register Form
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
        {errorRegister?<div style={{color:"red", textAlign:"center",marginBottom:"20px"}}>{errorRegister?errorRegister:""}</div>:""}
            
          <lable>User name</lable>
          <TextField variant="outlined"  margin="normal" required fullWidth id="useName" name="useName"  
          onChange= {handleChange('userName')}
            value={registerVal.userName}
          />
          
           <lable>First name</lable>
          <TextField variant="outlined"  margin="normal" required fullWidth id="firstName" name="firstName"  
          onChange= {handleChange('firstName')}
            value={registerVal.firstName}
          />
           <lable>Last name</lable>
          <TextField variant="outlined"  margin="normal" required fullWidth id="lastName" name="lastName"  
          onChange= {handleChange('lastName')}
            value={registerVal.lastName}
          />
           <lable>Password</lable>
          <TextField variant="outlined" margin="normal" required     fullWidth name="password" type="password" id="password" 
          value={registerVal.password}  onChange={handleChange('password')}
          />
           <lable>Email Address</lable>
          <TextField variant="outlined"  margin="normal" required fullWidth id="email" name="emailId" 
           onChange= {handleChange('emailId')}
            value={registerVal.emailId}
          />
           <lable>Primary Contact</lable>

              <NumberFormat
              margin="normal"
              required fullWidth
              type=""
              id="primaryContactNumber"
              name="primaryContactNumber"
              className={classes.textField1}
              value={registerVal.primaryContactNumber}
              onChange= {handleChange('primaryContactNumber')}
              variant="outlined"
              inputProps={{ style: inputStyle }}
              customInput={TextField}
              format="##########"
           
          />



           <lable >Secondary Contact</lable>
           <NumberFormat
              required fullWidth
              type=""
              id="secondaryContactNumber"
              name="secondaryContactNumber"
              className={classes.textField}
              value={registerVal.secondaryContactNumber}
              onChange= {handleChange('secondaryContactNumber')}
              variant="outlined"
              inputProps={{ style: inputStyle }}
              customInput={TextField}
              format="##########"
           
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
          <Divider className={classes.divider}/>
          <div className={classes.registerSec} >
     <div className={classes.loginNav} >
       <Typography style={{fontSize:"13px"}}>Already Have An Account? <Link to="/" className="link"> Log in here</Link></Typography></div>
   </div>
        </form>
      </div>

    );
}

export default withStyles(styles)(withRouter(RegisterComponent)); 

{/* <NumberFieldComponent format="##########" label="Secondary Contact Number" func={handleSecondaryContact} error={errorSecondaryContact}   type="" /> */}

// <NumberFormat
// required fullWidth
// type=""
// id="secondaryContactNumber"
// name="secondaryContactNumber"
// className={classes.textField}
// value={registerVal.secondaryContactNumber}
// onChange= {handleChange('secondaryContactNumber')}
// variant="outlined"
// inputProps={{ style: inputStyle }}
// customInput={TextField}
// format="##########"
// />


// "username": "string",
//   "firstName": "string",
//   "lastName": "string",
//   "password": "string",
//   "emailId": "string",
//   "primaryContactNumber": 0,
//   "secondaryContactNumber": 0