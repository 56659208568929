import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import "date-fns";
import * as moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, DatePicker} from "material-ui-pickers";
import './index.css';

const styles = theme => ({
    text:{
      color: "#001a4d",
      fontSize: "12px",
      fontWeight: "bold",
      textTransform: "uppercase",
      marginBottom:"-10px",
      paddingLeft:"5px"
    },
    formControl: {
        width: "100%",
        height:"40px",
        padding:"5px",
        marginTop:"0px"
        },
        errorAst:{
            color:"red",
            fontSize:"14px"
        } 
});

function DateSelect(props) {
const {classes, error,value}=props;

const [selectedDate, setSelectedDate] = React.useState(value);
const handleDateChange = date => {
  const dForm=moment(date).format('YYYY-MM-DD');
   props.func(dForm)
  setSelectedDate(date);
};

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset",fontSize:"14px",padding:"0px"};
return (
<form  noValidate>
<div >
<Typography className={classes.text}>Select Date</Typography>
<MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DatePicker 
    margin="normal"
    error={error}
    id="date-picker-dialog"
    label=""
    format="yyyy-MM-dd"
    value={selectedDate}
    onChange={(e)=>handleDateChange(e)}
   
    className={classes.formControl}
    placeholder={"yyyy-mm-dd"}
    inputProps={{ style: inputStyle }}
    KeyboardButtonProps={{
      'aria-label': 'change date',
    }}
  />
  </MuiPickersUtilsProvider>
</div>
</form>
);
}
export default withRouter(withStyles(styles)(DateSelect));