export const ACTION={
    GET_LOCATION:"GET_LOCATION",
    ERROR_LOCATION:"ERROR_LOCATION",
    GET_DEVICE_NAME:"GET_DEVICE_NAME",
    GET_DEVICE_DETAILS:"GET_DEVICE_DETAILS",
    GET_PUMP_CHECK_LIST:"GET_PUMP_CHECK_LIST",
    GET_BLOWER_CHECK_LIST:"GET_BLOWER_CHECK_LIST",
    SAVE_PUMP_CHECK_LIST:"SAVE_PUMP_CHECK_LIST",
    SAVE_BLOWER_CHECK_LIST:"SAVE_BLOWER_CHECK_LIST",
    SAVE_REPAIR_STATUS:"SAVE_REPAIR_STATUS",
    GET_REPAIR_STATUS:"GET_REPAIR_STATUS",
    GET_REPLACEMENT:"GET_REPLACEMENT",
    SAVE_REPLACEMENT:"GET_REPLACEMENT",

    //Report Action Types
    GET_PUMP_REPORTs:'GET_PUMP_REPORTs',
    GET_BLOWER_REPORT:"GET_BLOWER_REPORT",
    GET_REPORT_ERROR:"GET_REPORT_ERROR",
    GET_PUMP_REPAIR_REPORT:"GET_PUMP_REPAIR_REPORT",
    GET_PUMP_REPLACEMENT_REPORT:"GET_PUMP_REPLACEMENT_REPORT",
    GET_BLOWER_REPAIR_REPORT:"GET_PUMP_REPAIR_REPORT",
    GET_BLOWER_REPLACEMENT_REPORT:"GET_PUMP_REPLACEMENT_REPORT",

    IS_LOADING:"IS_LOADING"
}

export const LOGIN_CONSTANTS = {
    LOGIN: 'Login',
    CANCEL: 'Cancel',
    HOME: 'Home'
}

export const USER_DETAILS = {
    USERNAME:'Test',
    PWD: 'test123'
}
export const DeviceType=[
    {Id:1,Name:"Pump"},
    {Id:2,Name:"Blower"},
    
]

export const category=[
    {Id:"PM Check List",Name:"PM Check List"},
    {Id:"Repair",Name:"Repair"},
    {Id:"Replacement",Name:"Replacement"},
    
]

export const Status=[
    {Id:1,Name:"Open"},
    {Id:2,Name:"Close"},
    
]

export const pumpMechanicalCols = [
    {field: 'Id', header: 'Id'},
    {field: 'DeviceId', header: 'Device Id'},
    {field: 'DateTime', header: 'Date'},
    {field: 'DoneBy', header: 'Done By'},
    {field: 'PECINCharge', header: 'PEC InCharge'},
    {field: 'ConditionOfEquipement', header: 'Condition'},
    {field: 'MechanicalSeal', header: 'Mechanical Seal'},
    {field: 'Bearings', header: 'Bearings'},
    {field: 'GlandSeals', header: 'Gland Seals'},
    {field: 'Lubrication', header: 'Lubrication'},
    {field: 'Spider', header: 'Spider'},
    {field: 'ImpellerComb', header: 'Impeller / Comb'},
    {field: 'EquipmentCleaning', header: 'Equipment Cleaning'},
    {field: 'ValvesInspection', header: 'Valves Inspection'},
    {field: 'SafetyValvesPressureGaugeNRV', header: 'Safety valves/Pressure gauge/NRV'},
    {field: 'AllfoundationBolts', header: 'All foundation Bolts'},
    {field: 'ChainPulleyInspection', header: 'Chain pulley inspection'},
    {field: 'CouplingGearBox', header: 'Coupling/Gear box'},
    {field: 'OilLevelAndTopup', header: 'Oil Level And Topup'},
    {field: 'Greasing', header: 'Greasing'},
];


export const ElectricalCol = [
    {field: 'Id', header: 'Id'},
    {field: 'DeviceId', header: 'Device Id'},
    {field: 'DateTime', header: 'Date'},
    {field: 'DoneBy', header: 'Done By'},
    {field: 'TownshipPlantInCharge', header:'Plant InCharge'},
    {field: 'Contactor', header: 'Contactor'},
    {field: 'Relay',header: 'Relay'},
    {field: 'MCBOrMCCBOrMPCB',header: 'MCB /MCCB /MPCB'},
    {field: 'SwtichGear', header:'Swtich Gear'},
    {field: 'TerminalsInspection', header: ' Terminals Inspection'},
    {field: 'Amps',header: 'Amps'},
    {field: 'Voltage',header: 'Voltage'},
    {field: 'Earthings', header:'Earthings'},
    {field: 'CoolingFanInspection', header: 'Cooling Fan Inspection'},
    {field: 'InstrumentsInspection',header: 'Instruments Inspection'},
    {field: 'IndicationLamps',header: 'Indication Lamps'},
    {field: 'LugsCondition',header: 'Lugs Condition'},
];

export const RepairCols = [
    {field: 'Id', header: 'Id'},
    {field: 'DeviceId', header: 'Device Id'},
    {field: 'Problem', header: 'Problem'},
    {field: 'ActionTaken', header: 'Action Taken'},
    {field: 'RepairDate', header:'Repair Date'},
    {field: 'DeviceName', header:'Device Name'},
]


// export const pump_Machanical=[
//     {Name:"Mechanical seal"},
//     {Name:"Bearings"},
//     {Name:"Gland/seals"},
//     {Name:"Lubrication"},
//     {Name:"Spider"},
//     {Name:"Impeller / Comb"},
//     {Name:"Equipment cleaning"},
//     {Name:"Valves inspection"},
//     {Name:"Safety valves/Pressure gauge/NRV"},
//     {Name:"All foundation Bolts"},
//     {Name:"Chain pulley inspection"},
//     {Name:"Coupling/Gear box"},
//     {Name:"Oil level & Top up"},
//     {Name:"Greasing"}
// ];

export const blowerMechanicalCols = [
    {field: 'Id', header: 'Id'},
    {field: 'DeviceId', header: 'Device Id'},
    {field: 'DateTime', header: 'Date'},
    {field: 'DoneBy', header: 'Done By'},
    {field: 'PECINCharge', header: 'PEC InCharge'},
    {field: 'ConditionOfEquipement', header: 'Condition'},
    {field: 'OilSeal', header: 'Oil Seal'},
    {field: 'Bearings', header: 'Bearings'},
    {field: 'VBeltCondition', header: 'V-Belts condition'},
    {field: 'AirFilterCleaning', header: 'Air filter cleaning'},
    {field: 'OilLevel', header: 'Oil level'},
    {field: 'LubeMovement', header: 'Lube movement'},
    {field: 'EquipmentCleaning', header: 'Equipment Cleaning'},
    {field: 'ValvesInspection', header: 'Valves inspection'},
    {field: 'SafetyValvesPressureGaugeNRV', header: 'Safety valves/Pressure gauge/NRV'},
    {field: 'AllfoundationBolts', header: 'All foundation Bolts'},
    {field: 'ChainPulleyInspection', header: 'Chain pulley inspection'},
    {field: 'OutletAirPressure', header: 'Outlet air pressure'},
    {field: 'Noiselevel', header: 'Noise level'},
    {field: 'Greasing', header: 'Greasing'},
];


export const blower_Machanical=[
    {Name:"Oil seal"},
    {Name:"V-Belts condition"},
    {Name:"Bearings"},
    {Name:"Air filter cleaning"},
    {Name:"Oil level"},
    {Name:"Lube movement"},
    {Name:"Equipment cleaning"},
    {Name:"Valves inspection"},
    {Name:"Safety valves/Pressure gauge/NRV"},
    {Name:"All foundation Bolts"},
    {Name:"Chain pulley inspection"},
    {Name:"Outlet air pressure"},
    {Name:"Noise level"},
    {Name:"Greasing"}
    ]


export const Electrical=[
    {Name:"Contactor"},
    {Name:"Relay"},
    {Name:"MCB/MCCB/MPCB"},
    {Name:"Swtich Gear"},
    {Name:"Terminals inspection"},
    {Name:"Amps"},
    {Name:"Voltage"},
    {Name:"Earthings"},
    {Name:"Cooling Fan inspection"},
    {Name:"Instruments inspection"},
    {Name:"Indication lamps"},
    {Name:"Lugs condition"},
]



























                            











        
