import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import * as moment from 'moment';

//Application components
import DatePickers from "../Helpers/DatePicker";
import TextBox from "../Helpers/TextField";
const styles = theme => ({
  paperCheckList: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: "0px",
    padding: "10px",
    border: "1px solid gray",
    padding: "0px"
  },
  btnlight: {
    width: "30%",
    fontWeight: "bold",
    color: "#33ccff",
    backgroundColor: "transparent",
    border: "1px solid #33ccff",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: "0 auto"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto"
    },

    "&:hover": {
      backgroundColor: "#00ADEE",
      border: "1px solid#00ADEE",
      color: "#fff"
    }
  },
  txtAlign: {
    marginTop: "20px",
    textAlign: "right",
    width: "100%"
  },
  header: {
    textAlign: "left",
    backgroundColor: "#e6e6e6",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "10px",
    marginBottom: "30px"
  },
  marTop: {
    marginTop: "20px"
  }
});

const ReplacementComponent = props => {
  const {
    classes,
    getReplacementInfo,
    saveReplacementDate,
    typeID,
    getReplacementData
  } = props;

  const data = getReplacementInfo ? getReplacementInfo : {};

  const dateR = data ? data.ReplacementDate : "";

  const [repDate, setRepDate] = useState({
    Id: data ? data.Id : 0,
    DeviceId: data ? data.DeviceId : 0,
    ReplacementDate: dateR ? dateR : ""
  });

  const replacementDate = evt => {
    const value = evt.target.value;
    setRepDate({ ...repDate, [evt.target.name]: value });
  };

  const submintRepairStatus = () => {
    var d = new Date();
    const dForm=moment(d).format('YYYY-MM-DD');
    repDate.ReplacementDate=dForm;
    
    setRepDate()

    saveReplacementDate(repDate).then(res => {
      if (res && res.payload.statusText === "OK") {
        getReplacementData(typeID);
      }
    });
  };
  // const data=getReplacementInfo
  return (
    <div>
      <div className={classes.header}>Replacement</div>
      <Grid container spacing={1}>
        <Grid item lg={4} sm={4} xs={12}>
          <label style={{marginRight:"10px"}}>
            <b>Last replacement :</b>
          </label>
          <label Id="">{repDate.ReplacementDate.split("T")[0]}</label>
        </Grid>
        <Grid item lg={8} sm={8} xs={12}>
          <Button className={classes.btnlight} onClick={submintRepairStatus}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(withRouter(ReplacementComponent));
