import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import routes from "./router";
import { connect } from 'react-redux';
import { logoutAction } from './actions/loginAction';
import Header from '../src/components/Header';
import Footer from '../src/components/Footer';

import '../src/css/index.css'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
    fontSize: "18px"
  },
  list:{
    // backgroundColor:"green",
    
  }
}));

const App = props => {
  const classes = useStyles();
  const { userAuth, userLogout } = props;

  //<LockOutlinedIcon size="small" />
  return (<>
    
    <BrowserRouter>
    <Header />
      <Switch>
        {routes.map(route => (
          <Route
            path={route.path}
            component={route.component}
            key={route.id}
            exact={route.exact && true}
          />
        ))}
      </Switch>
    
    </BrowserRouter>
  </>);
}

const mapStateToProps = state => {
  return {
    userAuth: state.login.isLoggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    userLogout: () => dispatch(logoutAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);


