export const URLList = {
    Location:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetLocation",
    DeviceName:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetDeviceName",
    DeviceDetails:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetDeviceDetails",
    GetPumpCheck:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetPumpCheckList/",
    GetBlowerCheck:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetBlowerCheckList/",

    //Post URL

    PostPumpCheckList:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/CreateOrEditPumpChecklist",
    PostBlowerCheckList:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/CreateOrEditBlowerChecklist",

    //Repair section urls
    SaveRepairStatus:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/CreateOrEditRepairStatus",
    GetRepairStatus:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetRepairStatus/",
    GetReplacementStatus:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetReplacementStatus/",
    SaveReplacement:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/CreateOrEditReplacementStatus",

    //Report section urls
    PumpReport:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetPumpReport/",
    PumpRepairReport:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetPumpRepairReport/",
    PumpReplaceRpt:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetPumpReplacementReport/",
    BlowerReport:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetBlowerReport/",
    BlowerRepairReport:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetBlowerRepairReport/",
    BlowerReplaceRpt:"http://gpat.us-east-1.elasticbeanstalk.com/api/MTT/GetBlowerReplacementReport/",

    //User authentication
    Authenticate:"http://gpat.us-east-1.elasticbeanstalk.com/api/User/Authenticate",
    Register:"http://gpat.us-east-1.elasticbeanstalk.com/api/User/Register"







    // HTTP: "http://",
    // HTTPS: "https://",
    // LoginAPI: "User/Authenticate",
    // API: 'gpat.us-east-1.elasticbeanstalk.com/api/',
    // RegistrationAPI: 'User/Register',
    // GetCity: 'SlotBooking/GetTestCity',
    // GetTestCityAddress: 'SlotBooking/GetTestCityAddress',
    // GetAvailbleDateAPI: 'SlotBooking/GetAvailbleDateForTest',
    // GetAvailbleTimeAPI: 'SlotBooking/GetOpenSlots',
    // TestBookingAPI: 'SlotBooking/TestBooking',
  
    // getRegistrationURL: function() {
    //   return this.HTTP + this.API + this.RegistrationAPI;
    // },
    // getLoginURL: function() {
    //   return this.HTTP + this.API + this.LoginAPI;
    // },
    // getCityURL: function() {
    //   return this.HTTP + this.API + this.GetCity;
    // },
    // getTestCityAddressURL: function() {
    //   return this.HTTP + this.API + this.GetTestCityAddress;
    // },
    // getAvailbleDateForTestURL: function(){
    //   return this.HTTP + this.API + this.GetAvailbleDateAPI;
    // },
    // getAvailbleTimeForTestURL: function(){
    //   return this.HTTP + this.API + this.GetAvailbleTimeAPI;
    // },
    // testBookingURL: function(){
    //   return this.HTTP + this.API + this.TestBookingAPI;
    // }
  };
  
  //GetAvailbleDateForTest/
  