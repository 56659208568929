import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const MinHeightTextarea=(props)=> {
const {name,value,func}=props;

  return <TextareaAutosize style={{width:"100%",borderRadius:"4px",fontFamily:"verdana",fontSize:"11px"}}
  aria-label="minimum height" 
  rowsMin={2} 
  name={name}
  value={value}
  onChange={(e)=>func(e)}
  placeholder="Remarks" />;
}

export default MinHeightTextarea