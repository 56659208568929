import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from "react-router-dom"; 
import '../../index.css';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop:"150px",
    [theme.breakpoints.down("xs")]: {
      marginTop:"80px",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    border:"1px solid #384ec7",
    padding:"20px",
    borderRadius:"3px"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  divider:{
    height:"10px",
    marginBottom:"10px"
  },
  registerSec:{
    border:"1px solid #661a00",
    borderRadius:"0px",
   padding:"10px",
   [theme.breakpoints.down("xs")]:{ 
    padding:"10px",
  }
  },
}));

const Login = props => {
  const classes = useStyles();
  const {navToRegister, handleChange, loginVal, handleSubmit,errorLogin } = props;

 
  

  return (
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login Form
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
        {errorLogin?<div style={{color:"red", textAlign:"center",marginBottom:"20px"}}>{errorLogin?errorLogin:""}</div>:""}
          <lable>User name</lable>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="useName"
            name="useName"
            onChange= {handleChange('userName')}
            value={loginVal.userName}
          />
           <lable>Password</lable>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            value={loginVal.password}
            onChange={handleChange('password')}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
          <Divider className={classes.divider}/>
          <div className={classes.registerSec} >
     <div style={{textAlign:"center",fontSize:"14px"}}>
       <Typography style={{fontSize:"13px"}} onClick={navToRegister}>New to MTT? <Link to="/register" className="link">Create an account here</Link></Typography></div>
   </div>
        </form>
      </div>
  );
}

export default Login;