import { ACTION } from "../components/constants/appConstants";
const initialState = {
  location: [],
  locationError: "",
  deviceNames: [],
  deviceDetails:{},
  pumpBlowerCheckList:{},
  blowerCheckList:{},
  savePumpCheckListData:{},
  saveBlowerCheckListData:{},
  saveRepairStatus:{},
  getRepairStatusInfo:{},
  getReplacementInfo:{},
  isLoading:false,

  //Report 
  pumpReportList: [],
  reportError: [],
  blowerReport: [],
  pumpRepairReport: [],
  pumpReplacementReport:[],
  blowerReplacementReport:[],
  blowerRepairReport:[]
};



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.IS_LOADING:
      return { ...state, isLoading: action.payload };

    case ACTION.GET_LOCATION:
      return { ...state, location: action.payload,isLoading:false };

    case ACTION.ERROR_LOCATION:
      return { ...state, locationError: action.payload,isLoading:false };

    case ACTION.GET_DEVICE_NAME:
      return { ...state, deviceNames: action.payload,isLoading:false };

      case ACTION.GET_DEVICE_DETAILS:
      return { ...state, deviceDetails: action.payload,isLoading:false };

      case ACTION.GET_PUMP_CHECK_LIST:
      return { ...state, pumpBlowerCheckList: action.payload,isLoading:false };
      
      case ACTION.GET_BLOWER_CHECK_LIST:
      return { ...state, blowerCheckList: action.payload,isLoading:false };

      case ACTION.SAVE_PUMP_CHECK_LIST:
      return { ...state, savePumpCheckListData: action.payload,isLoading:false };

      case ACTION.SAVE_BLOWER_CHECK_LIST:
      return { ...state, saveBlowerCheckListData: action.payload,isLoading:false };

      case ACTION.SAVE_REPAIR_STATUS:
      return { ...state, saveRepairStatus: action.payload,isLoading:false };

      case ACTION.GET_REPAIR_STATUS:
      return { ...state, getRepairStatusInfo: action.payload,isLoading:false };

      case ACTION.GET_REPLACEMENT:
      return { ...state, getReplacementInfo: action.payload,isLoading:false };

      case ACTION.GET_PUMP_REPORTs:
        return { ...state, pumpReportList: action.payload, isLoading: false };
  
      case ACTION.GET_BLOWER_REPORT:
        return { ...state, blowerReport: action.payload, isLoading: false };
  
      case ACTION.GET_PUMP_REPAIR_REPORT:
        return { ...state, pumpRepairReport: action.payload, isLoading: false };
  
        case ACTION.GET_PUMP_REPLACEMENT_REPORT:
        return { ...state, pumpReplacementReport: action.payload, isLoading: false };
  
        // case ACTION.GET_BLOWER_REPAIR_REPORT:
        // return { ...state, blowerRepairReport: action.payload, isLoading: false };
  
        case ACTION.GET_BLOWER_REPLACEMENT_REPORT:
        return { ...state, blowerReplacementReport: action.payload, isLoading: false };
  
      case ACTION.GET_REPORT_ERROR:
        return { ...state, reportError: action.payload};

    default:
      return state;
  }
};
export default reducer;

