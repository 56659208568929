import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import ExpandSection from './ExpandSection';


import pump2 from '../../Images/pump2.jpg';
import bearing from '../../Images/bearing.jpg';
import motor1 from '../../Images/motor1.jpg';
import seal from '../../Images/seal.jpg';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth:"98%",
    margin:'0 auto',
    marginTop:"10px",
    //paddingBottom:"50px"
  },
  media: {
    height: 0,
    maxHeight:"100px",
    paddingTop: '56.25%', // 16:9

  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
    fontSize:"15px"
  },
  bntStyle:{
    backgroundColor:"#8a8a5c", color:"#fff",width:"100%",marginTop:"10px",borderRadius:"0px",
    padding:"5px", textAlign:"center"
  },
  bntStyle1:{
    backgroundColor:"#3333ff", color:"#fff",width:"100%",marginTop:"10px",borderRadius:"0px",
    padding:"5px", textAlign:"center"
  }
  
}));

function PumpDetails(props) {
    const {history,area,equipment}=props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
const pumpData=localStorage.getItem("header");

const BackToArea=()=>{
    history.push('/area');
}

const [showInfo,setShowInfo]=useState(false);
const showDetails=()=>{
    if(showInfo){
        setShowInfo(false);
    }else{
setShowInfo(true);
    }
    
}
const [showInfo1,setShowInfo1]=useState(false);
const showDetails1=()=>{
    if(showInfo1){
        setShowInfo1(false);
    }else{
        setShowInfo1(true);
    }
    
}
const [showInfo2,setShowInfo2]=useState(false);
const showDetails2=()=>{
    if(showInfo2){
        setShowInfo2(false);
    }else{
        setShowInfo2(true);
    }
    
}


const BearingContent=<div>
    <div className={classes.bntStyle} onClick={showDetails}>Replacement</div>
    {showInfo?<div style={{border:"1px solid #8a8a5c",width:"100%",margin:"0 auto"}}>
    <div style={{width:'90%', margin:"0 auto",padding:"10px"}}>
        <Typography style={{padding:"5px"}}><b>Last Replaced:</b> 02/07/2019</Typography>
        <Typography style={{padding:"5px"}}><b>Nex Due Date:</b> 30/06/2020</Typography>
        <div className={classes.bntStyle1}>Replace Now</div>
    </div>
    </div>:""}
    <div className={classes.bntStyle}>Greasing</div>
</div>

const MotorContent=<div>
    <div className={classes.bntStyle} onClick={showDetails1}>Replacement</div>
    {showInfo1?<div style={{border:"1px solid #8a8a5c",width:"100%",margin:"0 auto"}}>
    <div style={{width:'90%', margin:"0 auto",padding:"10px"}}>
        <Typography style={{padding:"5px"}}><b>Last Replaced:</b> 02/07/2019</Typography>
        <Typography style={{padding:"5px"}}><b>Nex Due Date:</b> 30/06/2020</Typography>
        <div className={classes.bntStyle1}>Replace Now</div>
    </div>
    </div>:""}
    <div className={classes.bntStyle}>Greasing</div>
</div>

const SealContent=<div>
    <div className={classes.bntStyle} onClick={showDetails2}>Replacement</div>
    {showInfo2?<div style={{border:"1px solid #8a8a5c",width:"100%",margin:"0 auto"}}>
    <div style={{width:'90%', margin:"0 auto",padding:"10px"}}>
        <Typography style={{padding:"5px"}}><b>Last Replaced:</b> 02/07/2019</Typography>
        <Typography style={{padding:"5px"}}><b>Nex Due Date:</b> 30/06/2020</Typography>
        <div className={classes.bntStyle1}>Replace Now</div>
    </div>
    </div>:""}
    <div className={classes.bntStyle}>Greasing</div>
</div>

  return (<div style={{marginBottom:"50px"}}>
    <Card className={classes.card}>
      <CardHeader 
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            MTT
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
          </IconButton>
        }
        title={equipment}
        subheader=""
      />
      <CardMedia
        className={classes.media}
        image={pump2}
        title="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
         <b>Address: </b> {area}
        </Typography>
      </CardContent>
     <div style={{marginTop:"10px"}}><ExpandSection content={BearingContent} image={bearing} HeaderText={equipment} /></div>
     <div style={{marginTop:"10px"}}><ExpandSection content={MotorContent} image={motor1} HeaderText="Motor" /></div>
     <div style={{marginTop:"10px"}}><ExpandSection content={SealContent} image={seal} HeaderText="Seal" /></div>
    </Card>
    </div>);
}

  export default withRouter(PumpDetails);
  // <ArrowBackIcon onClick={BackToArea} />