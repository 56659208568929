import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { logoutAction } from '../../actions/loginAction';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';



const styles = theme => ({
   
    title: {
      flexGrow: 1,
      fontSize: "18px"
    },
    menuHeader:{
            height:"80px",
            backgroundColor:"#e6e6e6",
            color:"#002266",
            fontWeight:"bold",
            paddingLeft:"20px"
    },
    app:{
      top:0,
      left:0,
      position:"fixed",
        backgroundColor:"#363940",
        height:"80px",
    },
    marLeft:{
        marginLeft:"20px"
    },
    toolbar:{
     height:"100px",
    }

  });

const Header=(props)=>{
    const {classes,history} = props;

    function handleLogout() {
        history.push("/")
      }
    
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
      <ListItem className={classes.menuHeader}>
          <span><PersonIcon /></span><span className={classes.marLeft}>Logged in user name</span>
      </ListItem>
        {['Menu 1', 'Menu 2'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Logout'].map((text, index) => (
          <ListItem button key={text} onClick={e => handleLogout()}>
            <ListItemIcon>{index % 2 === 0 ? <LockIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  
    return(
<AppBar position="static" className={classes.app}>

        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className=""
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon onClick={toggleDrawer('left', true)} />
            <Drawer  open={state.left} onClose={toggleDrawer('left', false)}>
              {sideList('left')}
            </Drawer>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Maintenance Tracking Tool
          </Typography>
         
        </Toolbar>
      </AppBar>

   )

}
export default withStyles(styles)(withRouter(Header));