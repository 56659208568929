import { combineReducers } from "redux";
import login from './loginReducer';
import search from './searchReducer';
import mttReducer from './mttReducer';
// import reportReducer from './reportReducer';

export default combineReducers({
  login,
  search,
  mttReducer,
  // reportReducer
});

