import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    root:{
        width:'80%',
        margin:'0 auto',
        [theme.breakpoints.down("xs")]: {
            width:'100%',
            margin:'0 auto',
          }
        },
        formControl: {
            width:"100%",
            textTransform: "capitalize",
          
          },
          lbl:{
            color:"#001a4d",
            textTransform: "uppercase",
            fontWeight:"bold",
            fontSize:"12px"
          },
          menuItems:{
            fontSize:"14px",
            textTransform: "capitalize",
          }
    
    });

const SelectCompoent=(props)=>{
    const {classes,text,displayData,func}=props;


    const [age, setAge] = React.useState('');
    const handleChange = event => {
        setAge(event.target.value);
        func(event.target.value)
      };

    const displayOptions=displayData.length!=0?displayData.map((v,index)=>(
        <MenuItem className={classes.menuItems} value={v.Id} key={index}>{v.Name}</MenuItem>
    )):"";  

    return(<div>
        <Typography className={classes.lbl} >{text}</Typography>
        <FormControl className={classes.formControl}>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={age}
          onChange={handleChange}
          
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {displayOptions}
        </Select>
      </FormControl>
    </div>)
}

export default withStyles(styles)(withRouter(SelectCompoent));

{/* <InputLabel id="demo-simple-select-helper-label">Age</InputLabel> */}