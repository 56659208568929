


import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";  

import LogbookComponent from '../../components/Logbook';

const Logbook  = ({ }) => {
 
  return (
    <div>
      <LogbookComponent />
    </div>
  );
};

const mapDispatchToProps = dispatch => {
    return {
    //   submitUserData: (postData) => dispatch(loginAction(postData))
    }
  }

export default withRouter(connect(null, mapDispatchToProps)(Logbook));
