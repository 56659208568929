import React from 'react';

import BlowerMachanicalCheckList from './blowerCheckList';
import PumpMachanicalCheckList from './pumpCheckList';
import ElectricalCheckList from './electricalCheckList';


const CheckList =(props)=>{
    const {type,data,deviceName,pumpChecklist,electricalChecklist,blowerChecklist}=props;

    const MTT_CheckList={
        pump:PumpMachanicalCheckList,
        blower:BlowerMachanicalCheckList,
        electrical:ElectricalCheckList,
    }

    const TagName=MTT_CheckList[type]

    return(<>{type?<TagName data={data} deviceName={deviceName} blowerChecklist={blowerChecklist} pumpChecklist={pumpChecklist} electricalChecklist={electricalChecklist} />:""}</>)
}
export default CheckList;