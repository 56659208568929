import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";


import {pumpMechanicalCols,blowerMechanicalCols,ElectricalCol,RepairCols} from "../constants/appConstants";
import ExportReport from './ExportData';

import Datatable from "./DataTable";

const styles = theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    marginTop: "0px",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      margin: "0 auto"
    }
  },
  header: {
    padding:"10px 0",textTransform:"uppercase",marginTop:"20px",
    fontSize: "14px",backgroundColor:"#e6e6e6",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    }
  },
  
});

const ReportComponent = props => {
  const { classes, PupmReportLsit,blowerReport,devType,
    actionItems,pumpRepairReport,pumpReplacementReport } = props;


 

const MechanicalCheckList = [];
const ElectricalCheckList = [];
if(blowerReport){
  for (var i = 0; i < blowerReport.length; i++){
    MechanicalCheckList[i] = blowerReport[i]["PumpMechanicalChecklist"];
    ElectricalCheckList[i] = blowerReport[i]["PumpElectricalInstrumentation"];
 }
}

if(PupmReportLsit){
  for (var i = 0; i < PupmReportLsit.length; i++){
    MechanicalCheckList[i] = PupmReportLsit[i]["PumpMechanicalChecklist"];
    ElectricalCheckList[i] = PupmReportLsit[i]["PumpElectricalInstrumentation"];
 }
}

const repairReport=[];
if(pumpRepairReport){
  for (var i = 0; i < pumpRepairReport.length; i++){
    const DeviceName=pumpRepairReport[i]["DeviceName"];
     var obj=Object.assign({},{DeviceName}, pumpRepairReport[i]["Repair"] );
    repairReport[i] =  obj;
 }
}

 
  return (
    <div className={classes.root}>
      {actionItems==="PM Check List"?<Grid container spacing={1}>
        <Grid item lg={12} xs={12} sm={12}>
        <div className={classes.header} ><b>{devType + ' ' + actionItems}</b>
        <span style={{float:"right"}}><ExportReport title={devType + ' ' + actionItems} data={MechanicalCheckList} headerData={pumpMechanicalCols} /> </span>
        </div> 
        
        <Datatable 
        pumpMechanicalCols={devType==="Pump"?pumpMechanicalCols:blowerMechanicalCols} data={MechanicalCheckList}
        />
        
        </Grid>
        <Grid item lg={12} xs={12} sm={12}>
        <div className={classes.header} ><b>{devType + ' '} Electrical / Instrumentation	
          </b>
          <span style={{float:"right"}}><ExportReport title={devType + ' ' +'Electrical / Instrumentation'} data={ElectricalCheckList} headerData={ElectricalCol} /> </span>
          </div> 
        <Datatable 
        pumpMechanicalCols={ElectricalCol} data={ElectricalCheckList}
        />
        </Grid>
      </Grid>:""}
      {actionItems==="Repair"?<Grid container spacing={1}>
        <Grid item lg={12} xs={12} sm={12}>
        <div className={classes.header} ><b>{devType + ' ' + actionItems + ' '}</b>
        <span style={{float:"right"}}><ExportReport title={devType + ' ' + actionItems} data={repairReport} headerData={RepairCols} /> </span>
        </div> 
        <Datatable 
        pumpMechanicalCols={RepairCols} data={repairReport}
        />
        </Grid>
      </Grid>:""}
      {actionItems==="Replacement"?<Grid container spacing={1}>
        <Grid item lg={12} xs={12} sm={12}>
        <div className={classes.header} ><b>{devType+ ' ' + actionItems + ' '}</b></div> 
        <Datatable 
        pumpMechanicalCols={RepairCols} data={repairReport}
        />
        </Grid>
      </Grid>:""}

    </div>
  );
};

export default withStyles(styles)(withRouter(ReportComponent));
