import { get, post } from "./baseApi";
// import { error, success } from '../Helper/Toast';
import { URLList } from "../config/URLS";
import { ACTION } from "../components/constants/appConstants";

export const getLocations = value => dispatch => {
  
  // dispatch({type:"IS_LOADING"});
  let url = URLList.Location;
  
  return get(url)
    .then(res => dispatch({ type: ACTION.GET_LOCATION, payload: res.data }))
    .catch(err => console.log("Error from post application data", err));
};

export const getDeviceName = (typeId, locId) => dispatch => {
  // dispatch({type:ACTION.IS_LOADING, payload:true});
  
  let url = URLList.DeviceName + "/" + typeId + "/" + locId;

  
  return get(url)
    .then(res => dispatch({ type: ACTION.GET_DEVICE_NAME, payload: res.data }))
    .catch(err => console.log("Error from post application data", err));
};

export const getDeviceDetails = (typeId, deviceId) => dispatch => {
  // dispatch({type:"IS_LOADING"});
  
  let url = URLList.DeviceDetails + "/" + typeId + "/" + deviceId;

  
  return get(url)
    .then(res =>
      dispatch({ type: ACTION.GET_DEVICE_DETAILS, payload: res.data })
    )
    .catch(err => console.log("Error from post application data", err));
};

export const getPumpCheckList = typeId => async dispatch => {
  // dispatch({type:"IS_LOADING"});
  dispatch({type:ACTION.IS_LOADING, payload:true});
  let url = URLList.GetPumpCheck + typeId;
  
  let res = await get(url);
  if (res && res.data) {
    return dispatch({ type: ACTION.GET_PUMP_CHECK_LIST, payload: res.data });
  } else {
    console.log("Error");
  }


};

export const getBlowerCheckList = typeId => async dispatch => {
   dispatch({type:ACTION.IS_LOADING, payload:true});
   
   let url = URLList.GetBlowerCheck + typeId;
   
   let res = await get(url);
   if (res && res.data) {
     return dispatch({ type: ACTION.GET_BLOWER_CHECK_LIST, payload: res.data });
   } else {
     console.log("Error");
   }
 
 };

//Post methods

export const savePumpCheckList = (postData) => dispatch => {
  dispatch({type:ACTION.IS_LOADING, payload:true});
  let url = URLList.PostPumpCheckList;
  return post(url,postData)
    .then(res => dispatch({ type: ACTION.SAVE_PUMP_CHECK_LIST, payload: res.data }))
    .catch(err => console.log("Error from post application data", err));

}

export const saveBlowerCheckList = (postData) => dispatch => {
  
  dispatch({type:ACTION.IS_LOADING, payload:true});
  let url = URLList.PostBlowerCheckList;

  return post(url,postData)
    .then(res => dispatch({ type: ACTION.SAVE_BLOWER_CHECK_LIST, payload: res }))
    .catch(err => console.log("Error from post application data", err));

}



// Repair action creators
export const getRepairStatus = (value) => dispatch => {
  
  dispatch({type:ACTION.IS_LOADING, payload:true});
  let url = URLList.GetRepairStatus+value;
  
  return get(url)
    .then(res => dispatch({ type: ACTION.GET_REPAIR_STATUS, payload: res.data }))
    .catch(err => console.log("Error from post application data", err));
};

export const saveRepairStatusPB = (postData) => dispatch => {
  
  dispatch({type:ACTION.IS_LOADING, payload:true});
  let url = URLList.SaveRepairStatus;

  return post(url,postData)
    .then(res => dispatch({ type: ACTION.SAVE_REPAIR_STATUS, payload: res }))
    .catch(err => console.log("Error from post application data", err));

}


export const getReplacementData = (value) => dispatch => {
  
  dispatch({type:ACTION.IS_LOADING, payload:true});
  let url = URLList.GetReplacementStatus+value;
  
  return get(url)
    .then(res => dispatch({ type: ACTION.GET_REPLACEMENT, payload: res.data }))
    .catch(err => console.log("Error from post application data", err));
};

export const saveReplacementDate = (postData) => dispatch => {
  
  dispatch({type:ACTION.IS_LOADING, payload:true});
  let url = URLList.SaveRepairStatus;

  return post(url,postData)
    .then(res => dispatch({ type: ACTION.SAVE_REPLACEMENT, payload: res }))
    .catch(err => console.log("Error from post application data", err));

}














// export const savePumpCheckList = (postData) => async dispatch => {
//   
//    dispatch({type:ACTION.IS_LOADING, payload:true});
//        let url = URLList.PostBlowerCheckList;
   
//      let res = await post(url, postData);
//      
//    	if(res && res.status === 200){
//        
//    		// success('Submitted successfully.');
//    		// dispatch({type: 'STOP_LOADER' });
//    		return dispatch({type:ACTION.SAVE_PUMP_CHECK_LIST, payload: res.data});
//    	}
//    	else if(true){
//    		// error('Network Error.');
//        // dispatch({type: 'STOP_LOADER' });
//        console.log("fsfds")
//    	}
//    }











// export const getDeviceDetails = () => async dispatch => {
// 	let url = URLList.DeviceDetails;

// 	let res = await get(url);
// 	if(res && res.data){
// 		return dispatch({type:ACTION.GET_DEVICE_DETAILS, payload: res.data});
// 	}
// 	else if(true){
// 		// error('Network Error.');
// 		//dispatch({type: ACTION.ERROR_LOCATION, payload:"Error while get location" });
// 	}
// };

// export const getCityAddress = (id) => async dispatch => {
// 	dispatch({type: 'START_LOADER' });
// 	let url = URLList.getTestCityAddressURL();
// 	url = url + `/${id}`;

// 	let res = await get(url);
// 	if(res && res.data){
// 		dispatch({type: 'STOP_LOADER' });
// 		return dispatch({type: 'GET_CITY_ADDRESS_SUCCESS', payload: res.data});
// 	}
// 	else if(true){
// 		error('Network Error.');
// 		dispatch({type: 'STOP_LOADER' });
// 	}
// };

// export const getAvailableDate = (id) => async dispatch => {
// 	dispatch({type: 'START_LOADER' });
// 	let url = URLList.getAvailbleDateForTestURL();
// 	url = url + `/${id}`;

// 	let res = await get(url);
// 	if(res && res.data){
// 		dispatch({type: 'STOP_LOADER' });
// 		return dispatch({type: 'GET_AVAILABLE_DATE_SUCCESS', payload: res.data});
// 	}
// 	else if(true){
// 		error('Network Error.');
// 		dispatch({type: 'STOP_LOADER' });
// 	}
// };
// //getAvailbleTimeForTestURL
// export const getAvailableTime = (postData) => async dispatch => {
// 	dispatch({type: 'START_LOADER' });
// 	let url = URLList.getAvailbleTimeForTestURL();
// 	url = url + `/${postData.AddressId}/${postData.date}`; //postData.date

// 	let res = await get(url);
// 	if(res && res.data){
// 		dispatch({type: 'STOP_LOADER' });
// 		return dispatch({type: 'GET_AVAILABLE_TIME_SUCCESS', payload: res.data});
// 	}
// 	else if(true){
// 		error('Network Error.');
// 		dispatch({type: 'STOP_LOADER' });
// 	}
// };

// export const testBooking = (postData) => async dispatch => {
// 	dispatch({type: 'START_LOADER' });
//     let url = URLList.testBookingURL();

// 	let res = await post(url, postData);
// 	if(res && res.status === 200){
// 		success('Submitted successfully.');
// 		dispatch({type: 'STOP_LOADER' });
// 		return dispatch({type: 'BOOKSLOT_SUCCESS', payload: res.data});
// 	}
// 	else if(true){
// 		error('Network Error.');
// 		dispatch({type: 'STOP_LOADER' });
// 	}
// }
