import React from "react";
import { Grid, Button } from "@material-ui/core";

import { pump_Machanical, Status } from "../constants/appConstants";
import SelectCompoent from "../Helpers/Dropdown";
import MinHeightTextarea from "../Helpers/TextArea";
import TextBox from '../Helpers/TextField';

const PumpMachanicalCheckList= (props)=>{

  const {data,pumpChecklist}=props;




  return (
    <div style={{fontSize:"12px",padding:"10px 15px 10px 10px"}}>
      <Grid container spacing={1}>
        
        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Mechanical seal</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="MechanicalSeal" value={data.MechanicalSeal} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="MechanicalSealRemarks" value={data.MechanicalSealRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Bearings</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="Bearings" value={data.Bearings} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="BearingsRemarks" value={data.BearingsRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Gland/seals</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="GlandSeals" value={data.GlandSeals} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="GlandSealsRemarks" value={data.GlandSealsRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Lubrication</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="Lubrication" value={data.Lubrication} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="LubricationRemarks" value={data.LubricationRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Spider</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="Spider" value={data.Spider} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="SpiderRemarks" value={data.SpiderRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Impeller / Comb</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="ImpellerComb" value={data.ImpellerComb} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="ImpellerCombRemarks" value={data.ImpellerCombRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Equipment cleaning</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="EquipmentCleaning" value={data.EquipmentCleaning} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="EquipmentCleaningRemarks" value={data.EquipmentCleaningRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Valves inspection</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="ValvesInspection" value={data.ValvesInspection} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="ValvesInspectionRemarks" value={data.ValvesInspectionRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Safety valves/Pressure gauge/NRV</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="SafetyValvesPressureGaugeNRV" value={data.SafetyValvesPressureGaugeNRV} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="SafetyValvesPressureGaugeNRVRemarks" value={data.SafetyValvesPressureGaugeNRVRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>All foundation Bolts</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="AllfoundationBolts" value={data.AllfoundationBolts} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="AllfoundationBoltsRemarks" value={data.AllfoundationBoltsRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Chain pulley inspection</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="ChainPulleyInspection" value={data.ChainPulleyInspection} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="ChainPulleyInspectionRemarks" value={data.ChainPulleyInspectionRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Coupling/Gear box</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="CouplingGearBox" value={data.CouplingGearBox} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="CouplingGearBoxRemarks" value={data.CouplingGearBoxRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Oil level & Top up</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="OilLevelAndTopup" value={data.OilLevelAndTopup} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="OilLevelAndTopupRemarks" value={data.OilLevelAndTopupRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Greasing</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={pumpChecklist} name="Greasing" value={data.Greasing} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={pumpChecklist} name="GreasingRemarks" value={data.GreasingRemarks} /></Grid>
        </Grid>
        </Grid>


      </Grid>
    </div>
  );
};

export default PumpMachanicalCheckList;