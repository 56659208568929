import React, { useState } from "react";
import { connect } from 'react-redux';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Login from '../../components/Login/LoginComponent';
import { login } from '../../actions/loginAction';
import { withRouter } from 'react-router-dom';
import Spinner from '../../components/Helpers/Spinner';
import {success,error} from '../../components/Helpers/Notification/Notification';

const LoginContainer = props => {
  const { submitUserData } = props;
  const [loginVal, setLoginVal] = useState({
    userName: null,
    password: null
  });

  const handleChange = (name) => (event) => {
    setLoginVal({...loginVal, [name]: event.target.value});
  }

  const [errorLogin, setErrorLogin] = useState("");
  const [isLoading,setIsLoading]=useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    submitUserData(loginVal).then(resp => validateLogin(resp));
  }

  const validateLogin = data => {
    let responseData=data?data.payload:"";      
      setIsLoading(false);
      const Token=responseData?responseData.Token:"";
      if(Token)
          {
            let UserData = JSON.stringify(responseData);
            sessionStorage.setItem("UserData", UserData)
            success("You have successfully logged In")
            props.history.push("/Dashboard") 
          }
      else{
        error("Something went wrong while login")
        setErrorLogin("Invalid username or password")
          console.log("Error while login");
      }
};

  const navToRegister=()=>{
    props.history.push("/register")
  }
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />{isLoading?<div style={{width:"100%",textAlign:"center",marginTop:"170px"}}><Spinner /></div>:
        <Login loginVal={loginVal} handleChange={handleChange} handleSubmit={handleSubmit} navToRegister={navToRegister} errorLogin={errorLogin} />}
    </Container>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    submitUserData: (postData) => dispatch(login(postData))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(LoginContainer));
