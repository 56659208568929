import toastr from 'toastr';
import './index.css';
toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick":null, 
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  }

export const success=(message)=>{
    toastr.success(message);
}
export const info=(message)=>{
    toastr.info(message);
}
export const warning=(message)=>{
    toastr.warning(message);
}
export const error=(message)=>{
    toastr.error(message);
}
