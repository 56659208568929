import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";

import SelectCompoent from "../../Helpers/Dropdown";
import {DeviceType,category } from "../../constants/appConstants";
import DatePickerComponent from '../../Helpers/DatePicker';


const styles = theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    marginTop: "0px",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      margin: "0 auto"
    }
  },
  header: {
    textAlign: "left",
    backgroundColor: "#e6e6e6",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "10px",
    marginTop: "-20px",
    marginBottom: "30px"
  },
  btnlight: {
    width: "100%",
    fontWeight: "bold",
    color: "#33ccff",
    backgroundColor: "transparent",
    border: "2px solid #33ccff",
    fontWeight:"bold",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: "0 auto",
      },
    [theme.breakpoints.down("xs")]: {
        width: "100%",
        margin: "0 auto",
      },
      
    "&:hover": {
      backgroundColor: "#00ADEE",
      border: "1px solid#00ADEE",
      color: "#fff"
    }
  },

});

const SelectReportComponent = props => {
  const { classes, getPumpReport,getBlowerReport,
    func,GetPumpRepairReport,funcActionItems,
    GetBlowerRepairReport,GetPumpReplacementReport
   } = props;


  const [dType,setDeviceType]=useState();
  const deviceType=(value)=> {
     setDeviceType(value)
     func(value===1?"Pump":"Blower")
   }  
   const [rptDate,setRptDate]=useState();
   const getDate=(value)=> {
     setRptDate(value)
    }
 
    const [actItem,setActions]=useState()
    const actionsItems=(value)=>{
     setActions(value)
     funcActionItems(value)
    }
 
    const getReport=(value)=>{
      const device_Type=dType==1?"Pump":"Blower";
      if(device_Type==="Pump" && actItem==="PM Check List"){
        getPumpReport(rptDate)
      }
      if(device_Type==="Blower" && actItem==="PM Check List" ){
        getBlowerReport(rptDate)
      }
      if(device_Type==="Pump" && actItem==="Repair"){
        GetPumpRepairReport(rptDate)
      }
      if(device_Type==="Blower" && actItem==="Repair"){
        GetBlowerRepairReport(rptDate)
      }
      if(device_Type==="Pump" && actItem==="Replacement"){
        GetPumpReplacementReport(rptDate)
      }
      
    }
 
 


  return (
    <div className={classes.root}>
         <div className={classes.header}>Report</div>
      <Grid container spacing={1}>
      <Grid item lg={12} xs={12} sm={12}>
          <Grid container spacing={2}>
          
            <Grid item lg={3} sm={3} xs={4}>
              <SelectCompoent text="Device Type" func={deviceType} displayData={DeviceType} />
            </Grid>
            <Grid item lg={3} sm={3} xs={4}>
              <SelectCompoent text="Category" func={actionsItems} displayData={category} />
            </Grid>
            <Grid item lg={3} sm={3} xs={4}>
             <DatePickerComponent func={getDate}/>
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
             <Button className={classes.btnlight} onClick={getReport}>Get Report</Button>
            </Grid>
            
          </Grid>
        </Grid>
        
      </Grid>
    </div>
  );
};

export default withStyles(styles)(withRouter(SelectReportComponent));
