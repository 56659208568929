import Login from '../containers/Login/LoginContainer';
// import Report from '../containers/Report';
import Dashboard from '../containers/Dashboard';
import Logbook from '../containers/Logbook';
import Register from '../containers/Register' ;

export default [
{ path: "/", component: Login, exact: true,id: 1},
{ path: "/logbook", component: Logbook,id: 2},
{ path: "/register", component: Register,id: 3},
{ path: "/dashboard",component:Dashboard,id: 4}
];