import React from "react";
import { Grid, Button } from "@material-ui/core";

import MinHeightTextarea from "../Helpers/TextArea";
import TextBox from '../Helpers/TextField';

const  ElectricalCheckList= (props)=>{

  const {data,electricalChecklist}=props; 

  

  return (
    <div style={{fontSize:"12px",padding:"10px 15px 10px 10px"}}>
      <Grid container spacing={1}>
        
      <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Contactor</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="Contactor" value={data.Contactor} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="ContactorRemarks" value={data.ContactorRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Relay</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="Relay" value={data.Relay} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="RelayRemarks" value={data.RelayRemarks} /></Grid>
        </Grid>
        </Grid>
        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>MCB/MCCB/ MPCB</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="MCBOrMCCBOrMPCB" value={data.MCBOrMCCBOrMPCB} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="MCBOrMCCBOrMPCBRemarks" value={data.MCBOrMCCBOrMPCBRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Swtich Gear</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="SwtichGear" value={data.SwtichGear} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="SwtichGearRemarks" value={data.SwtichGearRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Terminals inspection</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="TerminalsInspection" value={data.TerminalsInspection} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="TerminalsInspectionRemarks" value={data.TerminalsInspectionRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Amps</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="Amps" value={data.Amps} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="AmpsRemarks" value={data.AmpsRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Voltage</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="Voltage" value={data.Voltage} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="VoltageRemarks" value={data.VoltageRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Earthings</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="Earthings" value={data.Earthings} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="EarthingsRemarks" value={data.EarthingsRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Cooling Fan inspection</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="CoolingFanInspection" value={data.CoolingFanInspection} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="CoolingFanInspectionRemarks" value={data.CoolingFanInspectionRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Instruments inspection</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="InstrumentsInspection" value={data.InstrumentsInspection} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="InstrumentsInspectionRemarks" value={data.InstrumentsInspectionRemarks  } /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Indication lamps</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="IndicationLamps" value={data.IndicationLamps} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="IndicationLampsRemarks" value={data.IndicationLampsRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Lugs condition</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={electricalChecklist} name="LugsCondition" value={data.LugsCondition} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={electricalChecklist} name="LugsConditionRemarks" value={data.LugsConditionRemarks} /></Grid>
        </Grid>
        </Grid>



      </Grid>
    </div>
  );
};

export default ElectricalCheckList;
