import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getLocations,
  getDeviceName,
  getDeviceDetails,
  getPumpCheckList,
  getBlowerCheckList,
  savePumpCheckList,
  saveBlowerCheckList,
  saveRepairStatusPB,
  getRepairStatus,
  getReplacementData,
  saveReplacementDate
} from "../../actions/mttActions";
import {getPumpReport,getBlowerReport,GetPumpRepairReport,
  GetBlowerRepairReport,GetPumpReplacementReport} from "../../actions/reportActions"; 

import DashboardCompoment from "../../components/Dashboard";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeID: "",
      locationID: "",
      deviceTypeName: "",
      deviceName:""
    };
    this.deviceType = this.deviceType.bind(this);
    this.locationName = this.locationName.bind(this);
    this.getDeviceInfo = this.getDeviceInfo.bind(this);
  }
  
  

  componentDidMount() {
    this.props.getLocations();
  }

  deviceType(value) {
    ;
    if(value){
    this.setState({
      typeID: value,
      deviceTypeName: value === 1 ? "pump" : "blower"
    });
  }else{
    this.setState({
      // typeID: value,
      deviceTypeName: value
    });
  }
  }

  locationName(value) {
    this.setState({ locationID: value }, console.log(value));
    if (this.state.typeID && value) {
      const type = this.state.typeID;
      this.props.getDeviceName(type, value);
    }
  }
  getDeviceInfo(value) {
    if (this.state.typeID && value) {
      const type = this.state.typeID;
      this.setState({deviceName:value})
      this.props.getDeviceDetails(type, value);
    }
  }

  //Repair section functions
  

  render() {
    const { locationDetails, 
      deviceNameList, 
      deviceDetails,
      getPumpCheckList,
      blowerCheckList,
      getBlowerCheckList,
      savePumpCheckList,
      savePumpCheckListData,
      saveBlowerCheckList,
      saveBlowerCheckListData,
      saveRepairStatusPB,
      getRepairStatus,
      getRepairStatusInfo,
      saveRepairStatus,
      getReplacementData,
      getReplacementInfo,
      saveReplacementDate,

      //Report functions
      PupmReportLsit,
      blowerReport,
      pumpRepairReport,
      pumpReplacementReport,

      getPumpReport,
      getBlowerReport,
      GetPumpRepairReport,
      GetBlowerRepairReport,
      GetPumpReplacementReport,
      isLoad_Report

    } = this.props;
      ;
    const deviceInfoArray=[];

    if (Object.keys(deviceDetails).length != 0) {
      const objectArray = Object.entries(deviceDetails);
      objectArray.forEach(([key, value]) => {
        deviceInfoArray.push({Name:key,Value:value})
      });
    }
    ;


    return (
      <div>
        <DashboardCompoment
          typeID={this.state.typeID}
          loc_Id={this.state.locationID}
          device_Name={this.state.deviceName}
          deviceNameType={this.state.deviceTypeName}
          deviceType={this.deviceType}
          locationName={this.locationName}
          getDeviceInfo={this.getDeviceInfo}
          locations={locationDetails}
          deviceNames={deviceNameList}
          deviceData={deviceInfoArray}
          getPumpCheckList={getPumpCheckList}
          getBlowerCheckList={getBlowerCheckList}
          pbCheckListData={this.props.pbCheckList}
          blowerCheckList={this.props.blowerCheckList}
          isLoading={this.props.isLoading}
          savePumpCheckList={savePumpCheckList}
          saveBlowerCheckList={saveBlowerCheckList}
          getLocations={getLocations}

          saveRepairStatusPB={saveRepairStatusPB}
          getRepairStatusInfo={getRepairStatusInfo}
          getRepairStatus={getRepairStatus}

          saveRepairStatus={saveRepairStatus}
          getReplacementData={getReplacementData}
          getReplacementInfo={getReplacementInfo}
          saveReplacementDate={saveReplacementDate}

          //Reports
          getPumpReport={getPumpReport}
          getBlowerReport={getBlowerReport}
          GetPumpRepairReport={GetPumpRepairReport}
          GetBlowerRepairReport={GetBlowerRepairReport}

          PupmReportLsit={PupmReportLsit}
          blowerReport={blowerReport}
          pumpRepairReport={pumpRepairReport}
          GetPumpReplacementReport={GetPumpReplacementReport}
          pumpReplacementReport={pumpReplacementReport}
          isLoad_Report={isLoad_Report}


        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationDetails: state.mttReducer.location,
    deviceNameList: state.mttReducer.deviceNames,
    deviceDetails: state.mttReducer.deviceDetails,
    pbCheckList: state.mttReducer.pumpBlowerCheckList,
    blowerCheckList: state.mttReducer.blowerCheckList,
    savePumpCheckListData: state.mttReducer.savePumpCheckListData,
    saveBlowerCheckListData: state.mttReducer.saveBlowerCheckListData,
    saveRepairStatus: state.mttReducer.saveRepairStatus,
    getRepairStatusInfo: state.mttReducer.getRepairStatusInfo,
    getReplacementInfo: state.mttReducer.getReplacementInfo,
    isLoading: state.mttReducer.isLoading,

    //Report section data
    isLoad_Report: state.mttReducer.isLoading,
    PupmReportLsit:state.mttReducer.pumpReportList,
    blowerReport:state.mttReducer.blowerReport,
    pumpRepairReport:state.mttReducer.pumpRepairReport,
    pumpReplacementReport:state.mttReducer.pumpReplacementReport,
    //     ErrorData:state.reportReducer.reportError,
    
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getLocations, 
      getDeviceName, 
      getDeviceDetails,
      getPumpCheckList,
      getBlowerCheckList,
      savePumpCheckList,
      saveBlowerCheckList,
      saveRepairStatusPB,
      getRepairStatus,
      getReplacementData,
      saveReplacementDate,

      //Report Action
      getPumpReport,
      getBlowerReport,
      GetPumpRepairReport,
      GetBlowerRepairReport,
      GetPumpReplacementReport
     },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);


