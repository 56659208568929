import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: "98%",
    },
  },
}));

 const TextBox=(props)=> {
  const classes = useStyles();
  const {label,name,func,Id,value}=props;

  const onChangeEvt=(e)=>{
    func(e);
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField style={{marginTop:"0px",fontSize:"12px"}} id={Id} label={label} value={value}
       name={name} onChange={(e)=>onChangeEvt(e)} variant="outlined" />
    </form>
  );
}

export default TextBox;