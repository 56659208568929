import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";

//Application components
import SelectCompoent from "../Helpers/Dropdown";
import PumpDetails from "../Area/PumpDetails";

const styles = theme => ({
  root: {
    width: "99%",
    // margin: "0 auto",
    backgroundColor:"#f5f5f0",
    padding:"10px 0px 10px 10px",
    // marginRight:"20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto"
    }
  },
  buttonSection: {
    width: "60%",
    margin: "0 auto",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto",
      marginBottom: "150px"
    }
  },

 
  listItems:{
    width: "50%",
    fontWeight: "bold",
    marginRight: "10px",
    float: "left"
  },
  pbDetails:{
      fontSize:"14px"
  }
});

const ToolComponent = props => {
  const {classes,type,data} = props;

 


  const PBDetails = data.map((v, index) => (
    <Grid item lg={3} sm={6} xs={12} key={index}>
      <div className={classes.pbDetails}><div className={classes.listItems}>{v.Name + " :"}</div>
        <div>{v.Value}</div>
      </div>
    </Grid>
  ));

  return (
    <div className={classes.root}>
        <Grid container spacing={2}>
          {PBDetails}
        </Grid>
    </div>
  );
};
export default withStyles(styles)(withRouter(ToolComponent));


{/* <div className={classes.buttonSection}>
        <Grid container spacing={4}>
          <Grid item lg={4} sm={4} xs={4}>
            <Button className={classes.btnlight} onClick={finish}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </div> */}

// <Grid item lg={4} sm={4} xs={4}><Button className={classes.btnlight}>Save</Button></Grid>
//                 <Grid item lg={4} sm={4} xs={4}><Button className={classes.btnlight} onClick={finish}>Finish</Button></Grid>
//                 <Grid item lg={4} sm={4} xs={4}><Button className={classes.btnlight}>Cancel</Button></Grid>

