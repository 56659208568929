import React from "react";
import { Grid, Button } from "@material-ui/core";

import MinHeightTextarea from "../Helpers/TextArea";
import TextBox from '../Helpers/TextField';

const BlowerMachanicalCheckList= (props)=>{

  const {data,blowerChecklist}=props;


  return (
    <div style={{fontSize:"12px",padding:"10px 15px 10px 10px"}}>
      <Grid container spacing={1}>
        
      <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Oil seal</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="OilSeal" value={data.OilSeal} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="OilSealRemarks" value={data.OilSealRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>V-Belts condition</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="VBeltCondition" value={data.VBeltCondition} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="VBeltConditionRemarks" value={data.VBeltConditionRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Bearings</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="Bearings" value={data.Bearings} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="BearingsRemarks" value={data.BearingsRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Air filter cleaning</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="AirFilterCleaning" value={data.AirFilterCleaning} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="AirFilterCleaningRemarks" value={data.AirFilterCleaningRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Oil level</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="OilLevel" value={data.OilLevel} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="OilLevelRemarks" value={data.OilLevelRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Lube movement</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="LubeMovement" value={data.LubeMovement} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="LubeMovementRemarks" value={data.LubeMovementRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Equipment cleaning</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="EquipmentCleaning" value={data.EquipmentCleaning} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="EquipmentCleaningRemarks" value={data.EquipmentCleaningRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Valves inspection</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="ValvesInspection" value={data.ValvesInspection} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="ValvesInspectionRemarks" value={data.ValvesInspectionRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Safety valves/Pressure gauge/NRV</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="SafetyValvesPressureGaugeNRV" value={data.SafetyValvesPressureGaugeNRV} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="SafetyValvesPressureGaugeNRVRemarks" value={data.SafetyValvesPressureGaugeNRVRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>All foundation Bolts</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="AllFoundationBolts" value={data.AllFoundationBolts} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="AllFoundationBoltsRemarks" value={data.AllFoundationBoltsRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Chain pulley inspection</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="ChainPulleyInspection" value={data.ChainPulleyInspection} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="ChainPulleyInspectionRemarks" value={data.ChainPulleyInspectionRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Outlet air pressure</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="OutletAirPressure" value={data.OutletAirPressure} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="OutletAirPressureRemarks" value={data.OutletAirPressureRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Noise level</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="Noiselevel" value={data.Noiselevel} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="NoiselevelRemarks" value={data.NoiselevelRemarks} /></Grid>
        </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
        <Grid container spacing={2} >
              <Grid item lg={3} sm={4} xs={3}><b>Greasing</b></Grid>
              <Grid item lg={4} sm={4} xs={4}><TextBox func={blowerChecklist} name="Greasing" value={data.Greasing} /></Grid>
              <Grid item lg={5} sm={4} xs={5}><MinHeightTextarea func={blowerChecklist} name="GreasingRemarks" value={data.GreasingRemarks} /></Grid>
        </Grid>
        </Grid>
        


      </Grid>
    </div>
  );
};

export default BlowerMachanicalCheckList;

{
  /* <Paper className={classes.paperContent}>
              <div className={classes.header} >DEVICE INFORMATION </div>
              <div >
                  <Grid container spacing={2}>
              <Grid item lg={3} sm={4} xs={6} >
              <SelectCompoent text="Device Type" func={deviceType} displayData={DeviceType} />
              </Grid>
              <Grid item lg={3} sm={4} xs={6} >
              <SelectCompoent text="Location" func={locationName} displayData={locations} />
              </Grid>
              <Grid item lg={3}sm={4}  xs={6} >
              <SelectCompoent text="Device Name" func={getDeviceInfo} displayData={deviceNames} />
              </Grid>
              
              </Grid>
              </div>
              <div>
              {deviceData.length!=0?<ToolComponent  type={deviceNameType}  data={deviceData} />:""}
              </div>
              <div style={{padding:"10px",marginTop:"20px"}}> {tabSelected === "Maintanance" ?
                     "PM Check list": ""}
                    </div>
              </Paper> */
}
