

import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ReactExport from "react-data-export"; 

const styles = theme => ({
    
    btnlight: {
      width: "100%",
      color: "#000",
      backgroundColor: "#00ace6",
      border: "1px solid #00ace6",
      fontWeight:"bold",
      padding:"5px",
      borderRadius:"3px",
      marginTop:"-15px",
      [theme.breakpoints.down("sm")]: {
          width: "100%",
          margin: "0 auto",
        },
      [theme.breakpoints.down("xs")]: {
          width: "100%",
          margin: "0 auto",
        },
        
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#33ccff",
        border: "1px solid #33ccff",
        color: "#fff"
      }
    },
  });

const ExportReport = (props)=>{

    const {classes,data,headerData,title}=props;


    const ExcelFile = ReactExport.ExcelFile; 
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet; 
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn; 

    return(<ExcelFile filename={title} element={<button className={classes.btnlight}>Download</button>}> 
    <ExcelSheet data={data} name="">{
      headerData.map((v,index)=>(
        <ExcelColumn key={index} label={v.header} value={v.field}/>
      ))
    } 
    </ExcelSheet> 
    </ExcelFile> 
  )

}

export default withStyles(styles)(withRouter(ExportReport));