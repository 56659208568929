import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";

//Application components
import CheckList from "../CheckList";
import TextBox from "../Helpers/TextField";

const styles = theme => ({
  paperCheckList: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: "0px",
    padding: "10px",
    border: "1px solid gray",
    padding: "0px"
  },
  btnlight: {
    width: "30%",
    fontWeight: "bold",
    color: "#33ccff",
    backgroundColor: "transparent",
    border: "1px solid #33ccff",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: "0 auto"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto"
    },

    "&:hover": {
      backgroundColor: "#00ADEE",
      border: "1px solid#00ADEE",
      color: "#fff"
    }
  },
  txtAlign: {
    marginTop: "20px",
    textAlign: "right",
    width: "100%"
  },
  header: {
    textAlign: "left",
    backgroundColor: "#e6e6e6",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "10px",
    marginBottom:"30px"
  
  },
  marTop:{
    marginTop:"20px"
  },
  label:{
    padding:"10px",
    fontWeight:"bold"
  }
});

const RepairComponent = props => {
  const { classes, deviceNameType,typeID,data,saveRepairStatusPB,getRepairStatus } = props;


  const rGetData=data?data:{};


  const [repairState, setRepairState] = React.useState({
    Id:rGetData?rGetData.Id:"",
    DeviceId:rGetData?rGetData.DeviceId:typeID,
    Problem:rGetData?rGetData.Problem: "",
    ActionTaken:rGetData?rGetData.ActionTaken: ""
  })

  const postRepairDetails=(evt)=>{
    ;
    const value = evt.target.value;
    setRepairState({ ...repairState,[evt.target.name]: value
    });

    
  }
  
  const submintRepairStatus =()=>{
    saveRepairStatusPB(repairState).then(res=>{
      ;
      if(res && res.payload.statusText==="OK"){
        getRepairStatus(typeID)
      }
    })
  }

  return (
    <div>
      <div className={classes.header}>REPAIR</div>
      <Grid container spacing={1}>
        <Grid item lg={6} sm={6} xs={12}>
          <div className={classes.label}>Problem</div>
          <TextBox  name="Problem" value={repairState.Problem} func={postRepairDetails} Id="" />
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
        <div className={classes.label}>Action Taken</div>
          <TextBox  name="ActionTaken" value={repairState.ActionTaken} func={postRepairDetails} Id="" />
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <div className={classes.txtAlign}>
            <Button className={classes.btnlight} onClick={submintRepairStatus}>
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(withRouter(RepairComponent));
