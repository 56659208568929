import { get, post } from "./baseApi";
// import { error, success } from '../Helper/Toast';
import { URLList } from "../config/URLS";
import { ACTION } from "../components/constants/appConstants";




export const getPumpReport = value => async dispatch => {
    // dispatch({type:"IS_LOADING"});
    dispatch({type:ACTION.IS_LOADING, payload:true});
    let url = URLList.PumpReport+value;
    
    let res = await get(url);
    if (res && res.data) {
      return dispatch({ type: ACTION.GET_PUMP_REPORTs, payload: res.data });
    } else {
      console.log("Error");
    }
  };

  export const getBlowerReport = value => async dispatch => {
    dispatch({type:ACTION.IS_LOADING, payload:true});
    let url = URLList.BlowerReport+value;
    let res = await get(url);
    if (res && res.data) {
      return dispatch({ type: ACTION.GET_BLOWER_REPORT, payload: res.data });
    } else {
      console.log("Error");
    }
  };

  export const GetPumpRepairReport = value => async dispatch => {
    dispatch({type:ACTION.IS_LOADING, payload:true});
    let url = URLList.PumpRepairReport+value;
    let res = await get(url);
    if (res && res.data) {
      return dispatch({ type: ACTION.GET_PUMP_REPAIR_REPORT, payload: res.data });
    } else {
      console.log("Error");
    }
  };

  export const GetBlowerRepairReport = value => async dispatch => {
    dispatch({type:ACTION.IS_LOADING, payload:true});
    let url = URLList.BlowerRepairReport+value;
    let res = await get(url);
    if (res && res.data) {
      return dispatch({ type: ACTION.GET_PUMP_REPAIR_REPORT, payload: res.data });
    } else {
      console.log("Error");
    }
  };

  export const GetPumpReplacementReport = value => async dispatch => {
    dispatch({type:ACTION.IS_LOADING, payload:true});
    let url = URLList.PumpReplaceRpt+value;
    let res = await get(url);
    if (res && res.data) {
      return dispatch({ type: ACTION.GET_PUMP_REPLACEMENT_REPORT, payload: res.data });
    } else {
      console.log("Error");
    }
  };


  // export const GetPumpReplacementReport = (value) => dispatch => {
  //   dispatch({type:"IS_LOADING"});
  //   let url = URLList.PumpReplaceRpt;
  //   debugger;
  //   return get(url)
  //     .then(res => dispatch({ type: ACTION.GET_PUMP_REPLACEMENT_REPORT, payload: res.data }))
  //     .catch(err => dispatch({ type: ACTION.GET_REPORT_ERROR, payload: err }))
  // };

  // export const GetBlowerRepairReport = (value) => dispatch => {
  //   dispatch({type:"IS_LOADING"});
  //   let url = URLList.BlowerRepairReport;
  //   debugger;
  //   return get(url)
  //     .then(res => dispatch({ type: ACTION.GET_BLOWER_REPAIR_REPORT, payload: res.data }))
  //     .catch(err => dispatch({ type: ACTION.GET_REPORT_ERROR, payload: err }))
  // };

// export const getBlowerReport = (value) => dispatch => {
//     dispatch({type:"IS_LOADING"});
//     let url = URLList.BlowerReport;
//     debugger;
//     return get(url)
//       .then(res => dispatch({ type: ACTION.GET_BLOWER_REPORT, payload: res.data }))
//       .catch(err => dispatch({ type: ACTION.GET_REPORT_ERROR, payload: err }))
//   };

  // export const GetPumpRepairReport = (value) => dispatch => {
  //   dispatch({type:ACTION.IS_LOADING, payload:true});
  //   let url = URLList.PumpRepairReport;
  //   debugger;
  //   return get(url)
  //     .then(res => dispatch({ type: ACTION.GET_PUMP_REPAIR_REPORT, payload: res.data }))
  //     .catch(err => dispatch({ type: ACTION.GET_REPORT_ERROR, payload: err }))
  // };

  // export const GetPumpReplacementReport = (value) => dispatch => {
  //   dispatch({type:"IS_LOADING"});
  //   let url = URLList.PumpReplaceRpt;
  //   debugger;
  //   return get(url)
  //     .then(res => dispatch({ type: ACTION.GET_PUMP_REPLACEMENT_REPORT, payload: res.data }))
  //     .catch(err => dispatch({ type: ACTION.GET_REPORT_ERROR, payload: err }))
  // };

  

  export const GetBlowerReplacementReport = (value) => dispatch => {
    dispatch({type:"IS_LOADING"});
    let url = URLList.BlowerReplaceRpt;
    return get(url)
      .then(res => dispatch({ type: ACTION.GET_BLOWER_REPLACEMENT_REPORT, payload: res.data }))
      .catch(err => dispatch({ type: ACTION.GET_REPORT_ERROR, payload: err }))
  };















// export const saveRepairStatusPB = (postData) => dispatch => {
//   debugger;
//   dispatch({type:ACTION.IS_LOADING, payload:true});
//   let url = URLList.SaveRepairStatus;
// debugger;
//   return post(url,postData)
//     .then(res => dispatch({ type: ACTION.SAVE_REPAIR_STATUS, payload: res }))
//     .catch(err => console.log("Error from post application data", err));

// }














// // export const savePumpCheckList = (postData) => async dispatch => {
// //   debugger;
// //    dispatch({type:ACTION.IS_LOADING, payload:true});
// //        let url = URLList.PostBlowerCheckList;
   
// //      let res = await post(url, postData);
// //      debugger;
// //    	if(res && res.status === 200){
// //        debugger;
// //    		// success('Submitted successfully.');
// //    		// dispatch({type: 'STOP_LOADER' });
// //    		return dispatch({type:ACTION.SAVE_PUMP_CHECK_LIST, payload: res.data});
// //    	}
// //    	else if(true){
// //    		// error('Network Error.');
// //        // dispatch({type: 'STOP_LOADER' });
// //        console.log("fsfds")
// //    	}
// //    }







