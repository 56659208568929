import React, {useState} from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";

import MinHeightTextarea from "../Helpers/TextArea";
import SelectCompoent from "../Helpers/Dropdown";
import TextBox from "../Helpers/TextField";
import {DeviceType,category } from "../constants/appConstants";

const styles = theme => ({
  root: {
    // marginTop: "100px"
    fontSize:'12px'
  },
  header: {
    textAlign: "left",
    backgroundColor: "#e6e6e6",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "10px",
    marginTop: "-30px",
    marginBottom: "30px"
  },
});

const LogbookComponent = props => {
  const { classes } = props;

const [dType,setDeviceType]=useState();
 const deviceType=(value)=> {
    setDeviceType(value)
  }
  	
  


  return (
    <div className={classes.root}>
      <div className={classes.header}>Log Book</div>
      <Grid container spacing={1}>
        <Grid item lg={12} xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={3} xs={3}><b>Treated water flow meter	</b> </Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Final" func=""  /></Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Flow, m3" func=""  /></Grid>
            <Grid item lg={3} sm={3} xs={4}></Grid>
          </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={3} xs={3}><b>Tank water level in %		</b> </Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label=" Raw sewage" func=""  /></Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Filter feed" func=""  /></Grid>
            <Grid item lg={3} sm={3} xs={4}><TextBox label="Filter feed" func=""  /></Grid>
          </Grid>
        </Grid>
        	

        <Grid item lg={12} xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={3} xs={3}><b>Raw sewage pump	</b> </Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Start" func=""  /></Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Stop" func=""  /></Grid>
          </Grid>
        </Grid>
        	

        <Grid item lg={12} xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={3} xs={3}><b>Filter feed pump		</b> </Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Start" func=""  /></Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Stop" func=""  /></Grid>
          </Grid>
        </Grid>
        

        <Grid item lg={12} xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={3} xs={3}><b>SBR decant valve	</b> </Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Open" func=""  /></Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Close" func=""  /></Grid>
          </Grid>
        </Grid>
        		

        <Grid item lg={12} xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={3} xs={3}><b>Air blower</b> </Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Start" func=""  /></Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Stop"  func=""  /></Grid>
          </Grid>
        </Grid>
        	
        	

        <Grid item lg={12} xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={3} xs={3}><b>Sludge drain valve</b> </Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Open" func=""  /></Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Close" func=""  /></Grid>
          </Grid>
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={3} xs={3}><b>Filter press feed pump</b> </Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Start" func=""  /></Grid>
            <Grid item lg={3} sm={3} xs={4}> <TextBox label="Stop"  func=""  /></Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
};

export default withStyles(styles)(withRouter(LogbookComponent));
