import { USER_DETAILS } from '../components/constants/appConstants';
import { get, post } from "./baseApi";
import { URLList } from "../config/URLS";
import {authHeaderWithOutToken} from '../config/AuthenticationHeader';

export const login = userDetails => dispatch => {
	let url=URLList.Authenticate
	return post(url,userDetails )
		.then(res => dispatch({ type: "LOGIN", payload: res.data}))
		.catch(err => console.log("Error from Login ", err))
};

export const registration= userDetails => dispatch => {
	let url=URLList.Register;
	const userData=JSON.stringify(userDetails);
	return post(url,userData,authHeaderWithOutToken() )
	.then(res => {
		return res;});
};

// export const loginAction = (userDetails) => dispatch => {

// 	if(userDetails.userName === USER_DETAILS.USERNAME && userDetails.password === USER_DETAILS.PWD){
// 		dispatch({type: 'LOGIN_SUCCESS', payload: true});
// 	}
// 	else {
// 		alert('invalid User Name / Password');
// 	}
// };

export const logoutAction = () => dispatch => {
  dispatch({ type: "LOGOUT_SUCCESS", payload: false });
};


// userName: "Niruchiru"
// firstName: "niru"
// lastName: "s "
// password: "Chiru@2016"
// emailId: "niru@gmail.com"
// primaryContactNumber: "9535567574"
// secondaryContactNumber: "6362001027"