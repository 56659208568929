import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";

//Application components
import CheckList from "../CheckList";
import Spinner from '../Helpers/Spinner';


const styles = theme => ({
  
  paperCheckList: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: "0px",
    padding: "10px",
    border: "1px solid gray",
    padding: "0px"
  },
  paperCheckList1: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: "0px",
    padding: "10px",
    border: "1px solid gray",
    paddingBottom:"95px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom:"10px",
    },
  },
  btnlight: {
    width: "30%",
    fontWeight: "bold",
    color: "#33ccff",
    backgroundColor: "transparent",
    border: "1px solid #33ccff",
    [theme.breakpoints.down("sm")]: {
        width: "50%",
        margin: "0 auto",
      },
    [theme.breakpoints.down("xs")]: {
        width: "100%",
        margin: "0 auto",
      },
      
    "&:hover": {
      backgroundColor: "#00ADEE",
      border: "1px solid#00ADEE",
      color: "#fff"
    }
  },
  txtAlign:{
      marginTop:"20px",
      textAlign:"right",
      width:"100%"
  },
  header: {
    textAlign: "left",
    backgroundColor: "#e6e6e6",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "10px"
  }
});


const PMCheckList=(props)=>{


    const {classes,deviceNameType,data,savePumpCheckList,saveBlowerCheckList,
      getBlowerCheckList,typeID}=props;

  
  

  const B_data =data?data.BlowerMechanicalChecklist:{};

  const [bState, setBlowerState]=React.useState({
    Id:B_data?B_data.Id:"",									
    DeviceId:B_data?B_data.DeviceId:"",							
    DateTime:B_data?B_data.DateTime:"",							
    ConditionOfEquipement:B_data?B_data.ConditionOfEquipement:"",				
    DoneBy:B_data?B_data.DoneBy:"",								
    PECINCharge	:B_data?B_data.PECINCharge:"",						
    OilSeal:B_data?B_data.OilSeal:"",								
    VBeltCondition:B_data?B_data.VBeltCondition:"",						
    Bearings:B_data?B_data.Bearings:"",							
    AirFilterCleaning:B_data?B_data.AirFilterCleaning:"",					
    OilLevel:B_data?B_data.OilLevel:"",							
    LubeMovement:B_data?B_data.LubeMovement:"",						
    EquipmentCleaning:B_data?B_data.EquipmentCleaning:"",					
    ValvesInspection:B_data?B_data.ValvesInspection:"",					
    SafetyValvesPressureGaugeNRV:B_data?B_data.SafetyValvesPressureGaugeNRV:"",		
    AllFoundationBolts:B_data?B_data.AllFoundationBolts:"",					
    ChainPulleyInspection	:B_data?B_data.ChainPulleyInspection:"",			
    OutletAirPressure	:B_data?B_data.OutletAirPressure:"",				
    Noiselevel:B_data?B_data.Noiselevel:"",							
    Greasing:B_data?B_data.Greasing:"",							
    OilSealRemarks:B_data?B_data.OilSealRemarks:"",						
    VBeltConditionRemarks:B_data?B_data.VBeltConditionRemarks:"",				
    BearingsRemarks	:B_data?B_data.BearingsRemarks:"",					
    AirFilterCleaningRemarks:B_data?B_data.AirFilterCleaningRemarks:"",			
    OilLevelRemarks:B_data?B_data.OilLevelRemarks:"",						
    LubeMovementRemarks	:B_data?B_data.LubeMovementRemarks:"",				
    EquipmentCleaningRemarks:B_data?B_data.EquipmentCleaningRemarks:"",			
    ValvesInspectionRemarks	:B_data?B_data.ValvesInspectionRemarks:"",			
    SafetyValvesPressureGaugeNRVRemarks	:B_data?B_data.SafetyValvesPressureGaugeNRVRemarks:"",
    AllFoundationBoltsRemarks	:B_data?B_data.AllFoundationBoltsRemarks:"",		
    ChainPulleyInspectionRemarks	:B_data?B_data.ChainPulleyInspectionRemarks:"",	
    OutletAirPressureRemarks:B_data?B_data.OutletAirPressureRemarks:"",			
    NoiselevelRemarks:B_data?B_data.NoiselevelRemarks:"",					
    GreasingRemarks	:B_data?B_data.GreasingRemarks:"",					
});
const blowerChecklist=(evt)=>{
  
  const value = evt.target.value;
  setBlowerState({ ...bState,[evt.target.name]: value
  });
}

const E_data =data?(deviceNameType  ==="pump"?data.PumpElectricalInstrumentation:data.BlowerElectricalInstrumentation):{};

  const [elcState, setElectricalState]=React.useState({
    Id:E_data?E_data.Id:"",							
    DeviceId:E_data?E_data.DeviceId:"",						
    DateTime:E_data?E_data.DateTime:"",						
    DoneBy:E_data?E_data.DoneBy:"",						
    TownshipPlantInCharge:E_data?E_data.TownshipPlantInCharge:"",			
    Contactor:E_data?E_data.Contactor:"",						
    Relay:E_data?E_data.Relay:"",							
    MCBOrMCCBOrMPCB:E_data?E_data.MCBOrMCCBOrMPCB:"",				
    SwtichGear:E_data?E_data.SwtichGear:"",					
    TerminalsInspection:E_data?E_data.TerminalsInspection:"",			
    Amps:E_data?E_data.Amps:"",							
    Voltage:E_data?E_data.Voltage:"",						
    Earthings:E_data?E_data.Earthings:"",						
    CoolingFanInspection:E_data?E_data.CoolingFanInspection:"",			
    InstrumentsInspection:E_data?E_data.InstrumentsInspection:"",			
    IndicationLamps:E_data?E_data.IndicationLamps:"",				
    LugsCondition	:E_data?E_data.LugsCondition:"",				
    ContactorRemarks:E_data?E_data.ContactorRemarks:"",				
    RelayRemarks:E_data?E_data.RelayRemarks:"",					
    MCBOrMCCBOrMPCBRemarks:E_data?E_data.MCBOrMCCBOrMPCBRemarks:"",		
    SwtichGearRemarks:E_data?E_data.SwtichGearRemarks:"",				
    TerminalsInspectionRemarks:E_data?E_data.TerminalsInspectionRemarks:"",	
    AmpsRemarks:E_data?E_data.AmpsRemarks:"",
    VoltageRemarks:E_data?E_data.VoltageRemarks:"",				
    EarthingsRemarks:E_data?E_data.EarthingsRemarks:"",				
    CoolingFanInspectionRemarks:E_data?E_data.CoolingFanInspectionRemarks:"",	
    InstrumentsInspectionRemarks:E_data?E_data.InstrumentsInspectionRemarks:"",	
    IndicationLampsRemarks:E_data?E_data.IndicationLampsRemarks:"",		
    LugsConditionRemarks:E_data?E_data.LugsConditionRemarks:"",			
  });
  const electricalChecklist=(evt)=>{
    
    const value = evt.target.value;
    setElectricalState({ ...elcState,[evt.target.name]: value
    });
  }

  const pdata =data?data.PumpMechanicalChecklist:{};

  const [pState, setPumpCheckState]=React.useState({
    Id:pdata?pdata.Id:"",									
    DeviceId:pdata?pdata.DeviceId:"",								
    DateTime:pdata?pdata.DateTime:"",								
    DoneBy:pdata?pdata.DoneBy:"",								
    PECINCharge:pdata?pdata.PECINCharge:"",							
    ConditionOfEquipement:pdata?pdata:"",				
    MechanicalSeal:pdata?pdata.MechanicalSeal:"",						
    Bearings:pdata?pdata.Bearings:"",								
    GlandSeals:pdata?pdata.GlandSeals:"",							
    Lubrication:pdata?pdata.Lubrication:"",							
    Spider:pdata?pdata.Spider:"",								
    ImpellerComb:pdata?pdata.ImpellerComb:"",							
    EquipmentCleaning:pdata?pdata.EquipmentCleaning:"",					
    ValvesInspection:pdata?pdata.ValvesInspection:"",						
    SafetyValvesPressureGaugeNRV:pdata?pdata.SafetyValvesPressureGaugeNRV:"",			
    AllfoundationBolts:pdata?pdata.AllfoundationBolts:"",					
    ChainPulleyInspection:pdata?pdata.ChainPulleyInspection:"",				
    CouplingGearBox:pdata?pdata.CouplingGearBox:"",						
    OilLevelAndTopup:pdata?pdata.OilLevelAndTopup:"",						
    Greasing:pdata?pdata.Greasing:"",								
    MechanicalSealRemarks:pdata?pdata.MechanicalSealRemarks:"",				
    BearingsRemarks:pdata?pdata.BearingsRemarks:"",						
    GlandSealsRemarks:pdata?pdata.GlandSealsRemarks:"",					
    LubricationRemarks:pdata?pdata.LubricationRemarks:"",					
    SpiderRemarks:pdata?pdata.SpiderRemarks:"",						
    ImpellerCombRemarks:pdata?pdata.ImpellerCombRemarks:"",					
    EquipmentCleaningRemarks:pdata?pdata.EquipmentCleaningRemarks:"",				
    ValvesInspectionRemarks:pdata?pdata.ValvesInspectionRemarks:"",				
    SafetyValvesPressureGaugeNRVRemarks:pdata?pdata.SafetyValvesPressureGaugeNRVRemarks:"",	
    AllfoundationBoltsRemarks:pdata?pdata.AllfoundationBoltsRemarks:"",			
    ChainPulleyInspectionRemarks:pdata?pdata.ChainPulleyInspectionRemarks:"",			
    CouplingGearBoxRemarks:pdata?pdata.CouplingGearBoxRemarks:"",				
    OilLevelAndTopupRemarks:pdata?pdata.OilLevelAndTopupRemarks:"",				
    GreasingRemarks:pdata?pdata.GreasingRemarks:"",						
    
  });

  //PM Check list function
  const pumpChecklist=(evt)=>{
    
    const value = evt.target.value;
    setPumpCheckState({ ...pState,[evt.target.name]: value
    });
  }
  
 const savePumpBlowerCheckList=()=>{
  
   if(deviceNameType==="pump"){
    const pumpData={"pumpMechanicalChecklist":pState,"pumpElectricalInstrumentation":elcState}
    savePumpCheckList(pumpData).then(res=>{
      ;
      if(res && res.payload.statusText==="OK"){
        getBlowerCheckList(typeID)
      }
    })
   }
  if(deviceNameType==="blower"){
    ;
    const blowerData={"BlowerMechanicalChecklist":bState,"pumpElectricalInstrumentation":elcState}
    saveBlowerCheckList(blowerData).then(res=>{
      ;
      if(res && res.payload.statusText==="OK"){
        getBlowerCheckList(typeID)
      }
    })
    


  }

    }


    return(<><div>
        <div className={classes.header}>PM CHECK LIST </div>
<Grid container spacing={1} >
                  <Grid item lg={6} sm={6} xs={12}>
                  <div style={{textAlign:"center",padding:"10px",fontSize:"16px"}}><b>Mechanical Check List</b></div>
                  <Paper className={classes.paperCheckList}>
                      <CheckList type={deviceNameType} data={deviceNameType  ==="pump"?pState:bState} pumpChecklist={pumpChecklist} blowerChecklist={blowerChecklist}/></Paper>
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <div style={{textAlign:"center",padding:"10px",fontSize:"16px"}}><b>Electrical / Instrumentation</b></div>
                  <Paper className={classes.paperCheckList1}>
                  <CheckList type="electrical"  data={elcState} electricalChecklist={electricalChecklist} />
                  </Paper>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                      <div className={classes.txtAlign}>
                      <Button className={classes.btnlight} onClick={savePumpBlowerCheckList} >
                        Submit
                    </Button>
                      </div>
                  
                  </Grid>
                </Grid>
    </div></>);
}

export default withStyles(styles)(withRouter(PMCheckList));