import React, { useState } from "react";
import { connect } from 'react-redux';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import RegisterComponent from '../../components/Register';
import { registration } from '../../actions/loginAction';
import { withRouter } from 'react-router-dom';

import Spinner from '../../components/Helpers/Spinner';
import {success,error} from '../../components/Helpers/Notification/Notification';

const Register = props => {
  const { submitUserData } = props;
  const [registerVal, setRegisterVal] = useState({
    userName: "",
    firstName:"",
    lastName:"",
    password: "",
    emailId:"",
    primaryContactNumber:"",
    secondaryContactNumber:"",

  });


  const [isLoading,setIsLoading]=useState(false);

  const handleChange = (name) => (event) => {
    setRegisterVal({...registerVal, [name]: event.target.value});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    submitUserData(registerVal).then(resp => validateLogin(resp));
  }

  const [errorRegister, setErrorRegister] = useState("");
  const validateLogin = data => {
    if(data && data.status===200 || data && data.statusText==="OK")
        {
          setIsLoading(false);
          success("You have successfully registered");
          props.history.push('/');
        }
    else{
      setIsLoading(false);
      error("Something went wrong while register.")
      setErrorRegister("Error while register")
        console.log("Error while login");
    }
};
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />{isLoading?<div style={{width:"100%",textAlign:"center",marginTop:"170px"}}><Spinner /></div>:
        <RegisterComponent registerVal={registerVal} handleChange={handleChange} handleSubmit={handleSubmit} errorRegister={errorRegister} />}
    </Container>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    submitUserData: (postData) => dispatch(registration(postData))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Register));
